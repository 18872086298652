import { GET_USER, UPDATE_USER } from '@retainerclub/shared-api';
import {
  EmailInput,
  Fieldset,
  Form,
  Input,
  PhoneInput,
} from '../../../../shared';
import { initialValues, schema } from './helpers';

function UsersForm() {
  return (
    <Form
      initialValues={initialValues}
      schema={schema}
      sidebarTitle="Customer User"
      sidebarDescription="A user that can access the customer portal"
      createAction={UPDATE_USER}
      updateAction={UPDATE_USER}
      updateQuery={GET_USER}
      afterEditLink="customers/users"
      afterNewLink="customers/users"
      showOnEdit
      dataName="user"
    >
      <Fieldset
        title="Account Info"
        description="General account holder details"
      >
        <Input
          className="sm:col-span-3"
          required
          name="firstName"
          label="Account Holder First Name"
        />
        <Input
          className="sm:col-span-3"
          required
          name="lastName"
          label="Account Holder Last Name"
        />

        <EmailInput
          className="sm:col-span-3"
          required
          label="Account Holder Email"
        />

        <PhoneInput
          className="sm:col-span-3"
          required
          name="phone"
          label="Account Holder Phone"
        />
      </Fieldset>
    </Form>
  );
}

export default UsersForm;
