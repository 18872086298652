import { useQuery } from '@apollo/client';
import { DialogTitle } from '@headlessui/react';
import {
  AdditionalRetainerSelection,
  AdditionalSmileCarePlanSelection,
  Fieldset,
  LoadingOrError,
  Modal,
} from '@retainerclub/common';
import { GET_PAID_IN_OFFICE } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import PatientsShowPaidInOfficeModalInner from './PatientsShowPaidInOfficeModalInner';

function PatientsShowPaidInOfficeModal({
  handleCloseModal,
  currentRecord = null,
  open,
}) {
  const { data, error, loading } = useQuery(GET_PAID_IN_OFFICE, {
    variables: {
      partnerLocationId: currentRecord?.partnerLocationId,
    },
    skip: !(currentRecord?.partnerLocationId && open),
  });

  return (
    <Modal open={open} setOpen={handleCloseModal} size="6xl">
      <div>
        <div className="text-center">
          <DialogTitle
            as="h2"
            className="font-semibold text-2xl text-gray-900 leading-6"
          >
            Make A Purchase for&nbsp;
            <span className="capitalize">
              {currentRecord?.firstName} {currentRecord?.lastName}
            </span>
          </DialogTitle>
        </div>
      </div>
      <div className="mt-4">
        {currentRecord && (
          <PatientsShowPaidInOfficeModalInner
            record={currentRecord}
            handleCloseModal={handleCloseModal}
          >
            {(error || loading) && (
              <div className="h-[250px] w-full">
                <LoadingOrError loading={loading} error={error} />
              </div>
            )}

            {data?.paidInOffice && (
              <Fieldset
                title="Paid in office options"
                description="Would you like to add addiitonal products for the patient?"
                className="border-b-0 pb-0"
              >
                <div className="col-span-full mt-4 flex flex-row gap-4 sm:col-span-4">
                  <AdditionalRetainerSelection
                    partnerLocationId={currentRecord?.partnerLocationId}
                    patientId={currentRecord?.id}
                  />
                  <AdditionalSmileCarePlanSelection
                    partnerLocationId={currentRecord?.partnerLocationId}
                    patientId={currentRecord?.id}
                    hasPlan={currentRecord?.hasPlan}
                  />
                </div>
              </Fieldset>
            )}
          </PatientsShowPaidInOfficeModalInner>
        )}
      </div>
    </Modal>
  );
}

PatientsShowPaidInOfficeModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  currentRecord: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

export default PatientsShowPaidInOfficeModal;
