import { useQuery } from '@apollo/client';
import { FileDropzoneScan, LoadingOrError } from '@retainerclub/common';
import { useApp } from '@retainerclub/hooks';
import { GET_SCAN_FILES } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import PatientShowScansTable from './PatientShowScansTable/PatientShowScansTable';

function PatientShowScans({ patient }) {
  const [scanFiles, setScanFiles] = useState([]);
  const { isAdmin } = useApp();

  const { loading, error, refetch } = useQuery(GET_SCAN_FILES, {
    variables: { patientId: patient.id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.scanFiles) {
        setScanFiles(data.scanFiles);
      }
    },
  });

  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} />;
  }

  return (
    <div className="flex min-h-64 flex-col justify-stretch gap-2 md:flex-row">
      <div className={`basis-full pt-2 pr-2 ${isAdmin ? 'md:basis-2/3' : ''}`}>
        {scanFiles?.length > 0 ? (
          <PatientShowScansTable scanFiles={scanFiles} refetch={refetch} />
        ) : (
          <div className="flex size-full flex-col items-center justify-center">
            <h3 className="font-medium text-xl">No Scans Available</h3>
          </div>
        )}
      </div>
      {isAdmin && (
        <div className="flex h-full basis-full flex-col md:basis-1/3">
          <div>
            <FileDropzoneScan
              label="Drag Upper Arch Scan File(s) here"
              arches="upper"
              patientId={patient.id}
              account={patient.account}
              refetch={refetch}
            />
          </div>
          <div>
            <FileDropzoneScan
              label="Drag Pre-Production Scan File(s) here"
              arches="both"
              patientId={patient.id}
              account={patient.account}
              refetch={refetch}
              fileType="preproduction"
            />
          </div>
          <div>
            <FileDropzoneScan
              label="Drag Lower Arch Scan File(s) here"
              arches="lower"
              patientId={patient.id}
              account={patient.account}
              refetch={refetch}
            />
          </div>
        </div>
      )}
    </div>
  );
}

PatientShowScans.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientShowScans;
