import { useAuth } from '@retainerclub/hooks';
import { EmailInput, Input } from '../../../shared';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { initialValues, schema } from './helpers';

function SignIn() {
  const { login } = useAuth();
  const app = import.meta.env.VITE_APP_NAME;

  return (
    <AuthFormContainer
      submitFunction={login}
      submitTitle="Sign In"
      links={[{ id: 1, to: '/forgot-password', text: 'Forgot your password?' }]}
      initialValues={initialValues()}
      schema={schema()}
      showLegal={app === 'rc_partner'}
    >
      <>
        <EmailInput autoComplete="email" required />

        <Input
          type="password"
          autoComplete="current-password"
          required
          name="password"
        />
      </>
    </AuthFormContainer>
  );
}

export default SignIn;
