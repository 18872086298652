import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { MainTableLayout } from '../../layouts';
import {
  avatarCellRenderer,
  emailCellRenderer,
  emailValueGetter,
  nameValueGetter,
  phoneCellRenderer,
  stateCellRenderer,
  stateValueGetter,
} from '../../shared';

function PartnerUsersListDataTable({
  data,
  newTo = '/partners/users/new',
  height = 718,
  hideOrg = false,
  hideLoc = false,
}) {
  const app = import.meta.env.VITE_APP_NAME;

  const url = useMemo(
    () => (app.includes('partner') ? '/users/' : '/partners/users/'),
    [],
  );

  const columnDefs = [
    {
      field: 'imageUrl',
      headerName: '',
      cellStyle: { padding: '2px 2px', textAlign: 'center' },
      width: 65,
      suppressSizeToFit: true,
      suppressHeaderMenuButton: true,
      resizable: false,
      filter: false,
      sortable: false,
      cellRenderer: avatarCellRenderer,
    },
    {
      field: 'name',
      flex: 1,
      minWidth: 150,
      sort: 'asc',
      filter: 'agTextColumnFilter',
      valueGetter: nameValueGetter,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
      },
      onCellClicked: (params) => {
        params.context.navigate(`${url}${params.data.slug}`);
      },
    },
    {
      field: 'email',
      minWidth: 250,
      valueGetter: emailValueGetter,
      cellRenderer: emailCellRenderer,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'phone',
      cellRenderer: phoneCellRenderer,
      minWidth: 100,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'roleType.name',
      headerName: 'Base Role',
      minWidth: 100,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'partnerOrganization.name',
      headerName: 'Organization',
      minWidth: 150,
      hide: hideOrg,
      filter: 'agSetColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `/partners/organizations/${params.data.partnerOrganization.slug}`,
        );
      },
    },
    {
      field: 'partnerLocation.name',
      headerName: 'Location',
      minWidth: 150,
      hide: hideLoc,
      filter: 'agSetColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `/partners/locations/${params.data.partnerLocation.slug}`,
        );
      },
    },
    {
      field: 'lastLoginAt',
      headerName: 'Last Login',
      type: 'dateColumn',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'state',
      headerName: 'Status',
      filter: 'agSetColumnFilter',
      valueGetter: stateValueGetter,
      cellRenderer: stateCellRenderer,
      minWidth: 80,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      newTo={newTo}
      newTitle="Partner User"
      rowData={data.partnerUsers}
      height={height}
      pageTitle="Partner Users"
    />
  );
}

PartnerUsersListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  newTo: PropTypes.string,
  hideOrg: PropTypes.bool,
  hideLoc: PropTypes.bool,
};

export default PartnerUsersListDataTable;
