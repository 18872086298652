import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gridDateComparator from '../../transforms/gridDateComparator';
import timestampFormatter from '../../transforms/timestampFormatter';

function AgGridMain({
  columnDefs,
  defaultColDef = {},
  rowData = [],
  context = {},
  height = 718,
  hideFilters = false,
  masterDetail = false,
  detailCellRendererParams = null,
}) {
  const app = import.meta.env.VITE_APP_NAME;
  const gridRef = useRef();

  const navigate = useNavigate();

  const defaultColumnDef = useMemo(
    () => ({
      resizable: true,
      editable: false,
      suppressHeaderMenuButton: false,
      sortable: true,
      ...defaultColDef,
    }),
    [defaultColDef],
  );

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const sideBar = useMemo(
    () =>
      app.includes('admin')
        ? {
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: false,
                  suppressColumnSelectAll: false,
                  suppressColumnExpandAll: false,
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                  suppressExpandAll: false,
                  suppressFilterSearch: false,
                  suppressSyncLayoutWithGrid: true,
                },
              },
            ],
            defaultToolPanel: '',
          }
        : null,
    [],
  );

  return (
    <div
      className={
        document.documentElement.classList.contains('dark')
          ? 'ag-theme-rc-dark'
          : 'ag-theme-rc'
      }
      style={{ height }}
    >
      <AgGridReact
        ref={gridRef}
        modules={[
          ClientSideRowModelModule,
          CsvExportModule,
          ExcelExportModule,
          MasterDetailModule,
          MenuModule,
          StatusBarModule,
          SetFilterModule,
          ColumnsToolPanelModule,
          FiltersToolPanelModule,
        ]}
        sideBar={sideBar}
        popupParent={document.body}
        enableBrowserTooltips
        animateRows
        columnDefs={columnDefs}
        rowData={rowData}
        masterDetail={masterDetail}
        embedFullWidthRows={false}
        detailRowHeight={masterDetail ? 200 : null}
        detailCellRendererParams={detailCellRendererParams}
        context={{
          ...context,
          navigate,
        }}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={defaultColumnDef}
        statusBar={{
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
          ],
        }}
        columnTypes={{
          dateColumn: {
            width: 185,
            minWidth: 185,
            filter: !hideFilters ? 'agDateColumnFilter' : null,
            filterParams: !hideFilters
              ? {
                  newRowsAction: 'keep',
                  comparator: (filterLocalDateAtMidnight, cellValue) =>
                    gridDateComparator(filterLocalDateAtMidnight, cellValue),
                }
              : null,
            valueFormatter: (params) => {
              if (params.value) {
                return timestampFormatter(params.value);
              }

              return params.value;
            },
          },
        }}
      />
    </div>
  );
}

AgGridMain.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  defaultColDef: PropTypes.object,
  rowData: PropTypes.array.isRequired,
  context: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideFilters: PropTypes.bool,
  masterDetail: PropTypes.bool,
  detailCellRendererParams: PropTypes.object,
};

export default AgGridMain;
