import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_PARTNER_LOCS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import PartnerLocsListDataTable from './PartnerLocsListDataTable';

function PartnerLocsList({
  newTo = '/partners/locations/new',
  height = 718,
  hideHeader = false,
}) {
  const { error, loading, data } = useQuery(GET_PARTNER_LOCS);

  const app = import.meta.env.VITE_APP_NAME;

  if (loading || error) {
    return (
      <LoadingOrError
        error={error}
        loading={loading}
        title={`${app.includes('admin') ? 'Partner Locations' : 'Locations'}`}
      />
    );
  }

  return (
    <PartnerLocsListDataTable
      data={data}
      newTo={newTo}
      height={height}
      hideHeader={hideHeader}
    />
  );
}

PartnerLocsList.propTypes = {
  newTo: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideHeader: PropTypes.bool,
};

export default PartnerLocsList;
