import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon } from '@heroicons/react/20/solid';
import { Bars3Icon, UserIcon } from '@heroicons/react/24/outline';
import {
  Logo,
  NavigationDesktopDropdownMenu,
  NavigationDesktopSearchButton,
  Search,
} from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { GET_PARTNER_LOC } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import profileNavigation from './helpers/navLinks';

function Topbar(props) {
  const { setSidebarOpen } = props;
  const [searchOpen, setSearchOpen] = useState(false);
  const [partnerLocation, setPartnerLocation] = useState(null);

  const { currentUser } = useAuth();

  const _query = useQuery(GET_PARTNER_LOC, {
    skip: currentUser.roleLevel === 0,
    onCompleted: (data) => {
      setPartnerLocation(data.partnerLocation);
    },
  });

  const links = useMemo(() => {
    let l = profileNavigation;

    if (currentUser.roleLevel === 0) {
      l = [
        ...l,
        { name: 'Locations', to: '/locations', icon: BuildingOffice2Icon },
      ];
    }

    if (currentUser.roleLevel > 0 && partnerLocation) {
      l = [
        ...l,
        {
          name: 'Location',
          to: `/locations/${partnerLocation?.slug}`,
          icon: BuildingOffice2Icon,
        },
      ];
    }

    return l;
  }, [currentUser?.roleLevel, partnerLocation]);

  return (
    <>
      <div className="sticky top-0 z-[60] flex h-[68px] shrink-0 items-center justify-between gap-x-4 border-black/10 border-b bg-white px-6 py-4 shadow-sm sm:gap-x-6 lg:px-8">
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-black/70 "
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="size-6" aria-hidden="true" />
          </button>
        </div>

        <div className="flex self-stretch lg:flex-1">
          <NavLink to="/">
            <span className="sr-only">Retainer Club</span>
            <Logo color="black" width={122} height={35} />
          </NavLink>
        </div>

        <div className="flex gap-x-2 lg:gap-x-2">
          <div className="flex items-center gap-x-4 lg:relative lg:z-10 lg:gap-x-4">
            <NavigationDesktopSearchButton
              setSearchOpen={setSearchOpen}
              searchOpen={searchOpen}
            />

            <NavigationDesktopDropdownMenu
              title="Open user menu"
              navElements={links}
              icon={<UserIcon className="size-6" aria-hidden="true" />}
              showSignOut
            />
          </div>
        </div>
      </div>
      <Search open={searchOpen} setOpen={setSearchOpen} />
    </>
  );
}

Topbar.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
};

export default Topbar;
