import { compareAsc, parse } from 'date-fns';
import timestampFormatter from './timestampFormatter';

function gridDateComparator(filterLocalDateAtMidnight, cellValue) {
  const cellDate = parse(
    timestampFormatter(cellValue, false),
    'MM/dd/yy',
    new Date(),
  );
  const filterDate = filterLocalDateAtMidnight;

  return compareAsc(cellDate, filterDate);
}

export default gridDateComparator;
