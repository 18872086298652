import { useAuth } from '@retainerclub/hooks';
import { EmailInput } from '../../../shared';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { initialValues, schema } from './helpers';

function ForgotPassword() {
  const { forgot } = useAuth();

  return (
    <AuthFormContainer
      submitFunction={forgot}
      initialValues={initialValues()}
      schema={schema()}
      submitTitle="Send Reset Code"
      links={[
        { id: 1, to: '/reset-password', text: 'Already have a reset code?' },
        { id: 2, to: '/signin', text: 'Sign In', preText: 'Return to ' },
      ]}
    >
      <EmailInput autoComplete="email" required />
    </AuthFormContainer>
  );
}

export default ForgotPassword;
