import { useApp } from '@retainerclub/hooks';
import {
  CREATE_PARTNER_USER,
  GET_PARTNER_USER,
  UPDATE_PARTNER_USER,
} from '@retainerclub/shared-api';
import {
  EmailInput,
  FileDropzone,
  Form,
  Input,
  LanguageSelect,
  PhoneInput,
} from '../../../../shared';
import PartnerUsersFormInner from './PartnerUsersFormInner';
import { initialValues, schema } from './helpers';

function PartnerUsersForm() {
  const { isAdmin } = useApp();

  return (
    <Form
      initialValues={initialValues}
      schema={schema}
      sidebarTitle={`${isAdmin ? 'Partner User' : 'User'}`}
      sidebarDescription={`A ${isAdmin ? 'partner' : ''} user assigned to ${
        isAdmin ? 'a partner' : 'an'
      } organization and / or ${isAdmin ? 'partner' : ''} location.`}
      createAction={CREATE_PARTNER_USER}
      updateAction={UPDATE_PARTNER_USER}
      updateQuery={GET_PARTNER_USER}
      afterEditLink={`${isAdmin ? 'partners/users' : 'users'}`}
      afterNewLink={`${isAdmin ? 'partners/users' : 'users'}`}
      showOnEdit
      showOnNew
      dataName="partnerUser"
      secondColumn={
        <FileDropzone
          className="sm:col-span-full"
          label="Profile Picture"
          name="imageUrl"
        />
      }
    >
      <Input
        className="sm:col-span-3"
        required
        name="firstName"
        label="First Name"
      />
      <Input
        className="sm:col-span-3"
        required
        name="lastName"
        label="Last Name"
      />

      <EmailInput className="sm:col-span-3" autoComplete="email" required />

      <PhoneInput className="sm:col-span-3" required />

      <LanguageSelect className="sm:col-span-3" required />

      <PartnerUsersFormInner />
    </Form>
  );
}

export default PartnerUsersForm;
