import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import { Card, DonutChart, Title } from '@tremor/react';
import PropTypes from 'prop-types';

function RetainerRevenueGrouped({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'retainers_revenue_grouped' },
    notifyOnNetworkStatusChange: true,
  });

  const valueFormatter = (number) =>
    `${new Intl.NumberFormat('us').format(number).toString()}`;

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <Card>
      <Title>Lifetime Retainers at Retail Revenue</Title>
      {data.dashboard.metric.some((item) => item.sales > 0) ? (
        <DonutChart
          className="mt-6"
          data={data.dashboard.metric}
          category="sales"
          index="month"
          valueFormatter={valueFormatter}
          colors={[
            'slate',
            'violet',
            'indigo',
            'rose',
            'cyan',
            'amber',
            'slate',
            'violet',
            'indigo',
            'rose',
            'cyan',
            'amber',
          ]}
        />
      ) : (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="text-2xl">No data available</div>
        </div>
      )}
    </Card>
  ) : null;
}

RetainerRevenueGrouped.propTypes = {
  variables: PropTypes.object,
};

export default RetainerRevenueGrouped;
