import { Modal } from '@retainerclub/common';
import { StlViewer } from '@retainerclub/shared-3d';
import PropTypes from 'prop-types';

function PatientShowScansTableModal({
  open,
  handleCloseModal,
  selectedScanFile,
}) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl">
      <div>
        <StlViewer scanFileUrl={selectedScanFile} containerStyle="h-[600px]" />
      </div>
    </Modal>
  );
}

PatientShowScansTableModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  selectedScanFile: PropTypes.string.isRequired,
};

export default PatientShowScansTableModal;
