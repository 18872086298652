import { useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { MainTableLayout } from '../../layouts';
import {
  patientsValueGetter,
  sourceValueGetter,
  stateCellRenderer,
  stateValueGetter,
  userNameValueGetter,
} from '../../shared';

function ReferralsListDataTable({
  data,
  height = 718,
  newTo = '/customers/referrals/new',
  pageTitle = 'Referrals',
  hideFilters = false,
  pageActions = null,
  hideLoc = false,
  hideActions = false,
  hideHeader = false,
}) {
  const app = import.meta.env.VITE_APP_NAME;
  const { currentUser } = useAuth();

  const locationUrl = useMemo(
    () => (app.includes('partner') ? '/locations/' : '/partners/locations/'),
    [],
  );

  const columnDefs = [
    {
      field: 'slug',
      headerName: 'Referral',
      minWidth: 130,
      filter: 'agTextColumnFilter',
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'uppercase',
      },
      onCellClicked: (params) => {
        params.context.navigate(`/customers/referrals/${params.data.slug}`);
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 120,
      filter: 'agSetColumnFilter',
      valueGetter: sourceValueGetter,
    },
    {
      field: 'patient',
      headerName: 'Patient',
      filter: 'agTextColumnFilter',
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: 'bold',
      },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/patients/${params.data.patientAttributes.slug}`,
        );
      },
      minWidth: 160,
      valueGetter: patientsValueGetter,
    },
    {
      field: 'userAttributes.name',
      headerName: 'Account Holder',
      minWidth: 160,
      flex: 1,
      filter: 'agTextColumnFilter',
      valueGetter: userNameValueGetter,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
      },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/users/${params.data.userAttributes.slug}`,
        );
      },
    },
    {
      field: 'partnerLocation.name',
      headerName: app.includes('admin') ? 'Partner' : 'Location',
      minWidth: 200,
      filter: 'agSetColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `${locationUrl}${params.data.partnerLocation.slug}`,
        );
      },
      hide: currentUser.roleLevel !== 0 || hideLoc,
    },
    {
      field: 'state',
      headerName: 'Referral Status',
      minWidth: 160,
      filter: 'agSetColumnFilter',
      valueGetter: stateValueGetter,
      cellRenderer: stateCellRenderer,
    },
    {
      field: 'sentAt',
      headerName: 'Date Sent',
      type: 'dateColumn',
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'dateColumn',
      sort: 'desc',
      suppressHeaderMenuButton: true,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      newTo={newTo}
      newTitle="Referral"
      rowData={data.referrals}
      height={height}
      hideFilters={hideFilters}
      pageTitle={pageTitle}
      pageActions={pageActions}
      hideActions={hideActions}
      hideHeader={hideHeader}
    />
  );
}

ReferralsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  newTo: PropTypes.string,
  pageTitle: PropTypes.string,
  hideFilters: PropTypes.bool,
  pageActions: PropTypes.node,
  hideLoc: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

export default ReferralsListDataTable;
