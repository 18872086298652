import {
  GET_PARTNER_LOCS_SELECT,
  GET_PARTNER_ORGS_SELECT,
} from '@retainerclub/admin-api';
import { useApp, useFormValues } from '@retainerclub/hooks';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DynamicSelect, RoleSelect } from '../../../../shared';

function PartnerUsersFormInner() {
  const [role, setRole] = useState(null);

  const { pathname } = useLocation();
  const { partnerOrganizationId } = useFormValues();
  const { isAdmin } = useApp();

  return (
    <>
      <RoleSelect
        className="sm:col-span-3"
        modelScope="PartnerUser"
        required
        setRole={setRole}
      />

      {pathname.endsWith('/new') && isAdmin && (
        <DynamicSelect
          className="sm:col-span-3"
          dynamicQuery={GET_PARTNER_ORGS_SELECT}
          dataName="partnerOrganizations"
          required
          name="partnerOrganizationId"
          label="Partner Organization"
          searchable
        />
      )}

      {((isAdmin && role?.scopeToLocation && partnerOrganizationId) ||
        (!isAdmin && role?.scopeToLocation)) && (
        <DynamicSelect
          className="sm:col-span-3"
          dynamicQuery={GET_PARTNER_LOCS_SELECT}
          dataName="partnerLocations"
          required
          name="partnerLocationId"
          label="Partner Location"
          variables={{ partnerOrganizationId }}
          searchable
        />
      )}
    </>
  );
}

export default PartnerUsersFormInner;
