import { useQuery } from '@apollo/client';
import { TruckIcon } from '@heroicons/react/24/outline';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import DashboardTileLink from './DashboardTileLink';

function TotalExternalManufacturer({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'total_external_manufacturer' },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error) {
    return (
      <div className="relative h-[152px] overflow-hidden rounded-lg bg-white/10 px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <LoadingOrError error={error} loading={loading} />
      </div>
    );
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <DashboardTileLink
      title="Sent to Manufacturers"
      description="Total Production Orders sent v1+v2"
      metric={data.dashboard.metric}
      delta={data.dashboard.delta}
      deltaPercent={data.dashboard.delta_percent}
      deltaType={data.dashboard.delta_type}
      icon={TruckIcon}
    />
  ) : null;
}

TotalExternalManufacturer.propTypes = {
  variables: PropTypes.object,
};

export default TotalExternalManufacturer;
