import { useQuery } from '@apollo/client';
import { GET_PARTNER_USER } from '@retainerclub/shared-api';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  LoadingOrError,
  ShowBody,
  ShowDetails,
  ShowHeader,
} from '../../../../shared';
import PartnerUsersShowActions from './PartnerUsersShowActions';

function PartnerUsersShow() {
  const { slug } = useParams();
  const navigation = useNavigate();

  const app = import.meta.env.VITE_APP_NAME;

  const { data, loading, error } = useQuery(GET_PARTNER_USER, {
    variables: { slug },
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation(app.includes('admin') ? '/partners/users' : 'users');
  }

  const rootUrl = useMemo(
    () => (app.includes('admin') ? '/partners/users' : '/users'),
    [],
  );

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Partner User" />;
  }

  return (
    <>
      <ShowHeader
        title="Partner User"
        name={`${data.partnerUser.firstName} ${data.partnerUser.lastName}`}
        state={data.partnerUser.state}
        editTo={`${rootUrl}/${data.partnerUser.slug}/edit`}
        listTo={rootUrl}
        imageUrl={data.partnerUser.imageUrl}
        imageAlt={`${data.partnerUser.firstName} ${data.partnerUser.lastName}`}
        actions={<PartnerUsersShowActions partnerUser={data.partnerUser} />}
      />

      <ShowBody email={data.partnerUser.email} phone={data.partnerUser.phone}>
        <Card
          className="h-full p-4"
          heading="Details"
          wrapperClassName="h-full"
        >
          <ShowDetails record={data.partnerUser} />
        </Card>
      </ShowBody>
    </>
  );
}

export default PartnerUsersShow;
