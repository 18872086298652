import { Badge, ShowDetailsSection } from '@retainerclub/common';
import PropTypes from 'prop-types';

function SettingsBadge({ title, value }) {
  return (
    <ShowDetailsSection title={title} horizontal={false}>
      <Badge
        withDot={false}
        name={value ? 'Yes' : 'No'}
        color={value ? 'green' : 'yellow'}
      />
    </ShowDetailsSection>
  );
}

SettingsBadge.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default SettingsBadge;
