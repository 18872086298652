import { useQuery } from '@apollo/client';
import {
  LoadingOrError,
  ReferralsProductionListModalForm,
} from '@retainerclub/common';
import { GET_PATIENTS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ReferralsProductionListDataTable from './ReferralsProductionListDataTable';

function ReferralsProductionList({
  pageTitle = 'Cases',
  variables = { scanReady: false, activeAccount: true },
  hideActions = false,
}) {
  const [currentRecord, setCurrentRecord] = useState(null);
  const [open, setOpen] = useState(false);

  const { error, loading, data, refetch } = useQuery(GET_PATIENTS, {
    variables,
  });

  function handleOpenModal(record) {
    setCurrentRecord(record);
    setOpen(true);
  }

  function handleCloseModal() {
    setCurrentRecord(null);
    setOpen(false);
    refetch();
  }

  if (loading || error || !data) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return (
    <>
      <ReferralsProductionListDataTable
        records={data?.patients}
        pageTitle={pageTitle}
        hideActions={hideActions}
        handleOpenModal={(record) => handleOpenModal(record)}
      />
      <ReferralsProductionListModalForm
        currentRecord={currentRecord}
        open={open}
        handleCloseModal={() => handleCloseModal()}
      />
    </>
  );
}

ReferralsProductionList.propTypes = {
  pageTitle: PropTypes.string,
  variables: PropTypes.object,
  hideActions: PropTypes.bool,
};

export default ReferralsProductionList;
