import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_REFERRALS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import ReferralsListDataTable from './ReferralsListDataTable';

function ReferralsList({
  pageTitle = 'Referrals',
  variables = {},
  height = 718,
  hideHeader = false,
}) {
  const { error, loading, data } = useQuery(GET_REFERRALS, {
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return (
    <ReferralsListDataTable
      data={data}
      pageTitle={pageTitle}
      height={height}
      hideHeader={hideHeader}
    />
  );
}

ReferralsList.propTypes = {
  pageTitle: PropTypes.string,
  variables: PropTypes.object,
  height: PropTypes.number,
  hideHeader: PropTypes.bool,
};

export default ReferralsList;
