import { useQuery } from '@apollo/client';
import { TabGroup, TabList, TabPanel } from '@headlessui/react';
import { ShoppingBagIcon } from '@heroicons/react/20/solid';
import {
  LoadingOrError,
  RcTab,
  RcTabPanels,
  ReferralsShowDetails,
  SelectMenuItem,
  ShowActivity,
  ShowHeader,
  ShowStatus,
} from '@retainerclub/common';
import { useApp } from '@retainerclub/hooks';
import { GET_PATIENT } from '@retainerclub/shared-api';
import { OrdersList } from '@retainerclub/tables';
import { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import PatientDesignNotesShowCard from './PatientDesignNotesShowCard';
import PatientShowAccountNotes from './PatientShowAccountNotes';
import PatientShowCards from './PatientShowCards/PatientShowCards';
import PatientShowScans from './PatientShowScans';
import PatientsShowPaidInOfficeModal from './PatientsShowPaidInOfficeModal';

function PatientsShow() {
  const { slug } = useParams();
  const navigation = useNavigate();
  const { isAdmin } = useApp();
  const [open, setOpen] = useState(false);

  const { data, loading, error } = useQuery(GET_PATIENT, {
    variables: { slug },
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation('/customers/patients');
  }

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Patient" />;
  }

  return (
    <>
      <ShowHeader
        title="Patient"
        name={`${data.patient.firstName} ${data.patient.lastName}`}
        listTo="/customers/patients"
        editTo={`/customers/patients/${data.patient.slug}/edit`}
        legacyId={data?.patient?.legacyId}
        actions={
          <SelectMenuItem icon={ShoppingBagIcon} onClick={() => setOpen(true)}>
            Make In Office Purchase
          </SelectMenuItem>
        }
      />
      <PatientShowCards patient={data.patient} />

      <TabGroup as="div">
        <div className="-mx-4 flex overflow-x-auto sm:mx-0">
          <div className="ml-4 flex-auto sm:px-0">
            <TabList className="flex space-x-2">
              <RcTab name="Scans" />
              <RcTab name="Purchases" />
              {data?.patient?.referral && <RcTab name="Referral" />}
              {isAdmin && <RcTab name="Account Notes" />}
            </TabList>
          </div>
        </div>
        <RcTabPanels height="sm">
          <TabPanel>
            <PatientShowScans patient={data.patient} />
          </TabPanel>
          <TabPanel>
            <OrdersList
              variables={{ patientId: data.patient.id }}
              height={300}
              pageTitle={null}
            />
          </TabPanel>
          {data?.patient?.referral && (
            <TabPanel>
              <div className="mb-4 flex flex-row items-center gap-x-8">
                <div>
                  <p className="text-center font-bold text-black/60 text-sm leading-4 sm:text-left">
                    Referral
                  </p>
                  <h3 className="mt-0 text-xl uppercase">
                    <NavLink
                      to={`/customers/referrals/${data.patient.referral.hexCode}`}
                      className="text-rcprimary-600 hover:text-rcprimary-400"
                    >
                      {data.patient.referral.hexCode}
                    </NavLink>
                  </h3>
                </div>
                <div>
                  <ShowStatus state={data.patient.referral.state} />
                </div>
              </div>
              <div className="w-full md:w-2/3">
                <ReferralsShowDetails
                  referral={data.patient.referral}
                  hidePartner
                />
              </div>
            </TabPanel>
          )}
          {isAdmin && (
            <TabPanel>
              <PatientShowAccountNotes
                patientId={data.patient.id}
                accountId={data.patient.accountId}
              />
            </TabPanel>
          )}
        </RcTabPanels>
      </TabGroup>
      <PatientDesignNotesShowCard patient={data.patient} />
      <ShowActivity id={data?.patient?.id} klass="Patient" />
      {open && data?.patient && (
        <PatientsShowPaidInOfficeModal
          handleCloseModal={() => setOpen(false)}
          currentRecord={data.patient}
          open={open}
        />
      )}
    </>
  );
}

export default PatientsShow;
