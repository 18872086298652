import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { MainTableLayout } from '../../layouts';
import {
  activeCellRenderer,
  activeValueGetter,
  cityRegionValueGetter,
  emailCellRenderer,
  imageCellRenderer,
  phoneCellRenderer,
} from '../../shared';

function PartnerLocsListDataTable({
  data,
  newTo = '/partners/locations/new',
  height = 718,
  hideHeader = false,
}) {
  const app = import.meta.env.VITE_APP_NAME;

  const url = useMemo(
    () => (app.includes('partner') ? '/locations/' : '/partners/locations/'),
    [],
  );

  const columnDefs = [
    {
      field: 'imageUrl',
      headerName: '',
      cellStyle: { padding: '2px 2px', textAlign: 'center' },
      width: 65,
      suppressSizeToFit: true,
      suppressHeaderMenuButton: true,
      resizable: false,
      filter: false,
      sortable: false,
      cellRenderer: imageCellRenderer,
    },
    {
      field: 'name',
      flex: 1,
      sort: 'asc',
      filter: 'agTextColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(`${url}${params.data.slug}`);
      },
    },
    {
      field: 'email',
      minWidth: 250,
      cellRenderer: emailCellRenderer,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'phone',
      cellRenderer: phoneCellRenderer,
      minWidth: 140,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'website',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      cellStyle: { cursor: 'pointer' },
    },
    {
      field: 'address.city',
      headerName: 'City/Region',
      minWidth: 250,
      filter: 'agTextColumnFilter',
      cellRenderer: cityRegionValueGetter,
    },
    {
      field: 'active',
      headerName: 'Status',
      filter: 'agSetColumnFilter',
      valueGetter: activeValueGetter,
      cellRenderer: activeCellRenderer,
      minWidth: 80,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      newTo={newTo}
      newTitle="Partner Location"
      rowData={data.partnerLocations}
      height={height}
      pageTitle="Partner Locations"
      hideHeader={hideHeader}
    />
  );
}

PartnerLocsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  newTo: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideHeader: PropTypes.bool,
};

export default PartnerLocsListDataTable;
