import { DialogTitle } from '@headlessui/react';
import { Modal, TextArea } from '@retainerclub/common';
import PropTypes from 'prop-types';
import AccountNoteModalWrapper from './AccountNoteModalWrapper';

function AccountNoteModal({
  open,
  handleCloseModal,
  patientId = null,
  accountId = null,
  currentNote = null,
  refetch = () => null,
}) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl">
      <div>
        <div className="text-center">
          <DialogTitle
            as="h2"
            className="font-semibold text-2xl text-rcgray-900 capitalize leading-6"
          >
            {currentNote ? 'Update' : 'Add a New'} Account Note
          </DialogTitle>
        </div>
      </div>
      <div className="mt-4">
        <AccountNoteModalWrapper
          note={currentNote}
          patientId={patientId}
          accountId={accountId}
          handleCloseModal={handleCloseModal}
          refetch={refetch}
        >
          <div className="sm:col-span-6">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
              <TextArea className="sm:col-span-full" name="message" />
            </div>
          </div>
        </AccountNoteModalWrapper>
      </div>
    </Modal>
  );
}

AccountNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  currentNote: PropTypes.object,
  patientId: PropTypes.string,
  accountId: PropTypes.string,
  refetch: PropTypes.func,
};

export default AccountNoteModal;
