import PropTypes from 'prop-types';
import { useState } from 'react';
import PatientShowScansTableModal from './PatientShowScansTableModal';
import PatientShowScansTableRow from './PatientShowScansTableRow';

function PatientShowScansTable({ scanFiles, refetch }) {
  const [open, setOpen] = useState(false);
  const [selectedScanFile, setSelectedScanFile] = useState(null);

  function handleCloseModal() {
    setOpen(false);
    setSelectedScanFile(null);
  }

  function handleOpenModal(scanFile) {
    setSelectedScanFile(scanFile);
    setOpen(true);
  }

  return (
    <>
      <div className="relative max-h-[300px] overflow-hidden overflow-y-scroll">
        <table className="min-w-full border-separate border-spacing-0 rounded-md border border-rcgray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 rounded-tl-md border-gray-300 border-b bg-rcgray-200/50 px-3 py-3.5 text-left font-semibold text-gray-900 text-sm"
              >
                File Name
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 border-gray-300 border-b bg-rcgray-200/50 px-3 py-3.5 text-left font-semibold text-gray-900 text-sm"
              >
                Arch(es)
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 border-gray-300 border-b bg-rcgray-200/50 px-3 py-3.5 text-left font-semibold text-gray-900 text-sm"
              >
                Scanned At
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 border-gray-300 border-b bg-rcgray-200/50 px-3 py-3.5 text-left font-semibold text-gray-900 text-sm"
              >
                Uploaded At
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 rounded-tr-md border-gray-300 border-b bg-rcgray-200/50 px-3 py-3.5 text-right font-semibold text-gray-900 text-sm"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {scanFiles.map((scanFile) => (
              <PatientShowScansTableRow
                key={scanFile.id}
                scanFile={scanFile}
                refetch={refetch}
                handleOpenModal={handleOpenModal}
              />
            ))}
          </tbody>
        </table>
      </div>
      {selectedScanFile && open && (
        <PatientShowScansTableModal
          open={open}
          handleCloseModal={handleCloseModal}
          selectedScanFile={selectedScanFile}
        />
      )}
    </>
  );
}

PatientShowScansTable.propTypes = {
  scanFiles: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PatientShowScansTable;
