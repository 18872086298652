import {
  Badge,
  Card,
  RcDl,
  ShowDetailsSection,
  timestampFormatter,
} from '@retainerclub/common';
import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import SettingsBadge from './SettingsBadge';

function PatientShowCardsSettings({ patient }) {
  const { isAdmin } = useApp();

  return (
    <Card className="h-full p-4" heading="Settings" wrapperClassName="h-full">
      <div className="mt-4" />
      <div className="flex flex-col gap-x-8 gap-y-4 sm:flex-row">
        <div className="flex-1">
          <RcDl dividers={false} gridCols="sm:grid-cols-3 gap-y-4">
            <ShowDetailsSection title="Arches" horizontal={false}>
              <span className="capitalize">{patient.arches}</span>
            </ShowDetailsSection>
            <SettingsBadge title="EOT Included?" value={patient.includeEot} />
            <SettingsBadge
              title="Ship First Set To Clinic?"
              value={patient.sendToClinic}
            />
            {isAdmin && (
              <SettingsBadge
                title="Sent First Set To Clinic?"
                value={patient.consumedSendToClinic}
              />
            )}
            <SettingsBadge
              title="Purchased Smile Care Plan?"
              value={patient.hasPlan}
            />
          </RcDl>
        </div>
        <div className="flex-1">
          <RcDl dividers={false} gridCols="sm:grid-cols-3 gap-y-4">
            <ShowDetailsSection
              title={
                patient.scanReady ? 'Scan Sent At' : 'Estimated Scan Ready Date'
              }
              horizontal={false}
            >
              {patient.scanReady && patient.scanSentAt
                ? timestampFormatter(patient.scanSentAt)
                : patient.scanReady && !patient.scanSentAt
                  ? 'Not Provided'
                  : !patient.scanReady && patient.scanReadyAt
                    ? timestampFormatter(patient.scanReadyAt)
                    : 'Not Provided'}
            </ShowDetailsSection>
            <SettingsBadge title="Scan Ready?" value={patient.scanReady} />
            <ShowDetailsSection title="Retainer Bank" horizontal={false}>
              <Badge
                withDot={false}
                name={`${patient.retainerBank > 0 ? patient.retainerBank : 'None'}
                Available`}
                color={patient.retainerBank < 1 ? 'yellow' : 'green'}
              />
            </ShowDetailsSection>
            <ShowDetailsSection title="Default Lab Notes?" horizontal={false}>
              <Badge
                withDot={false}
                name={patient.settingsOverride ? 'No' : 'Yes'}
                color={patient.settingsOverride ? 'yellow' : 'green'}
              />
            </ShowDetailsSection>
          </RcDl>
        </div>
      </div>
    </Card>
  );
}

PatientShowCardsSettings.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientShowCardsSettings;
