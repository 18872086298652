import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_PATIENTS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import PatientsListAddPatient from './PatientsListAddPatient/PatientsListAddPatient';
import PatientsListDataTable from './PatientsListDataTable';

function PatientsList({
  pageTitle = 'All Patients',
  variables = {},
  height = 718,
  showAddPatient = false,
}) {
  const { error, loading, data, refetch } = useQuery(GET_PATIENTS, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Patients" />;
  }

  return (
    <>
      {showAddPatient && variables?.accountId && (
        <div className="flex justify-end">
          <PatientsListAddPatient
            accountId={variables?.accountId}
            refetch={refetch}
          />
        </div>
      )}
      <PatientsListDataTable
        data={data}
        pageTitle={pageTitle}
        height={height}
      />
    </>
  );
}

PatientsList.propTypes = {
  pageTitle: PropTypes.string,
  variables: PropTypes.object,
  height: PropTypes.number,
  showAddPatient: PropTypes.bool,
};

export default PatientsList;
