import { BaseLoader } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout/MainLayout';

function RequireAuth() {
  const { authLoading, currentUser } = useAuth();
  const location = useLocation();

  if (authLoading) {
    return <BaseLoader />;
  }

  if (currentUser) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );
  }

  return <Navigate to="/signin" state={{ from: location }} replace />;
}

export default RequireAuth;
