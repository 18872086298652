import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import PropTypes from 'prop-types';
import AccountsListDataTable from './AccountsListDataTable';

function AccountsList({ query }) {
  const { error, loading, data } = useQuery(query);

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return <AccountsListDataTable data={data} />;
}

AccountsList.propTypes = {
  query: PropTypes.object.isRequired,
};

export default AccountsList;
