import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    accountId: Yup.string().nullable(true),
    patientId: Yup.string().nullable(true),
    message: Yup.string().required('Note Is Required'),
  });
}

export default schema;
