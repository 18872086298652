import { useQuery } from '@apollo/client';
import { TabGroup, TabList, TabPanel } from '@headlessui/react';
import {
  Card,
  LoadingOrError,
  RcTab,
  RcTabPanels,
  ShowActivity,
  ShowBody,
  ShowCustomerAddresses,
  ShowDetails,
  ShowHeader,
} from '@retainerclub/common';
import { GET_USER } from '@retainerclub/shared-api';
import { OrdersList, PatientsList, ReferralsList } from '@retainerclub/tables';
import { useNavigate, useParams } from 'react-router-dom';

function UsersShow() {
  const { slug } = useParams();
  const navigation = useNavigate();

  const { data, loading, error, refetch } = useQuery(GET_USER, {
    variables: { slug },
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation('/customers/users');
  }

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Customer" />;
  }

  return (
    <>
      <ShowHeader
        title="Customer"
        name={`${data.user.firstName} ${data.user.lastName}`}
        state={data.user.state}
        editTo={`/customers/users/${data.user.slug}/edit`}
        listTo="/customers/users"
        legacyId={data?.user?.legacyId}
      />
      <ShowBody
        email={data.user.email}
        phone={data.user.phone}
        rightColumn={
          <ShowCustomerAddresses
            refetch={refetch}
            accountId={data.user.account.id}
            accountAddresses={data.user.account.accountAddresses}
          />
        }
      >
        <Card
          className="h-full p-4"
          heading="Details"
          wrapperClassName="h-full"
        >
          <ShowDetails record={data.user} />
        </Card>
      </ShowBody>
      <TabGroup as="div">
        <div className="-mx-4 flex overflow-x-auto sm:mx-0">
          <div className="ml-4 flex-auto sm:px-0">
            <TabList className="flex space-x-2">
              <RcTab name="Patients" />
              <RcTab name="Referrals" />
              <RcTab name="Purchases" />
            </TabList>
          </div>
        </div>
        <RcTabPanels height="sm">
          <TabPanel>
            <PatientsList
              variables={{ accountId: data.user.account.id }}
              height={300}
              pageTitle={null}
            />
          </TabPanel>
          <TabPanel>
            <ReferralsList
              variables={{ accountId: data.user.account.id }}
              height={300}
              pageTitle={null}
              hideHeader
            />
          </TabPanel>
          <TabPanel>
            <OrdersList
              variables={{ accountId: data.user.account.id }}
              height={300}
              pageTitle={null}
            />
          </TabPanel>
        </RcTabPanels>
      </TabGroup>

      <ShowActivity id={data?.user?.id} klass="User" />
    </>
  );
}

export default UsersShow;
