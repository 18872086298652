import { useApp, useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { MainTableLayout } from '../../layouts';
import {
  emailCellRenderer,
  emailValueGetter,
  importedCellRenderer,
  importedValueGetter,
  includeEotCellRenderer,
  nameValueGetter,
  phoneCellRenderer,
  stateCellRenderer,
  stateValueGetter,
} from '../../shared';

function UsersListDataTable({ data, hideLoc = false }) {
  const { isPartner } = useApp();
  const { currentUser } = useAuth();

  const locationUrl = useMemo(
    () => (isPartner ? '/locations/' : '/partners/locations/'),
    [isPartner],
  );

  const columnDefs = [
    {
      field: 'name',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      filterParams: {
        joinOperator: 'OR',
      },
      valueGetter: nameValueGetter,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: 'bold',
      },
      onCellClicked: (params) => {
        params.context.navigate(`/customers/users/${params.data.slug}`);
      },
    },
    {
      field: 'account.accountNumber',
      headerName: 'Account #',
      minWidth: 120,
      filter: 'agTextColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/accounts/${params.data.account.slug}`,
        );
      },
    },
    {
      field: 'partnerLocation.name',
      headerName: isPartner ? 'Location' : 'Partner',
      minWidth: 200,
      filter: 'agSetColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `${locationUrl}${params.data.partnerLocation.slug}`,
        );
      },
      hide: currentUser.roleLevel !== 0 || hideLoc,
    },
    {
      field: 'email',
      minWidth: 250,
      valueGetter: emailValueGetter,
      cellRenderer: emailCellRenderer,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'phone',
      cellRenderer: phoneCellRenderer,
      minWidth: 140,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'state',
      headerName: 'Status',
      filter: 'agSetColumnFilter',
      valueGetter: stateValueGetter,
      cellRenderer: stateCellRenderer,
      minWidth: 100,
    },
    {
      field: 'withoutPlan',
      headerName: 'Active Plan?',
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: (params) => (params?.data?.withoutPlan ? 'no' : 'yes'),
      cellRenderer: includeEotCellRenderer,
      minWidth: 100,
    },
    {
      field: 'imported',
      headerName: '1.0 Import?',
      suppressHeaderMenuButton: true,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: importedValueGetter,
      cellRenderer: importedCellRenderer,
    },
    {
      field: 'lastLoginAt',
      headerName: 'Last Login',
      type: 'dateColumn',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'createdAt',
      sort: 'desc',
      headerName: 'Date Created',
      type: 'dateColumn',
      suppressHeaderMenuButton: true,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={data.users}
      pageTitle={isPartner ? 'Customers ' : 'Customer Users'}
    />
  );
}

UsersListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  hideLoc: PropTypes.bool,
};

export default UsersListDataTable;
