import { useAuth } from '@retainerclub/hooks';
import { Input } from '../../../shared';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { initialValues, schema } from './helpers';

function CompletePassword() {
  const { newPassword } = useAuth();

  return (
    <AuthFormContainer
      submitFunction={newPassword}
      initialValues={initialValues()}
      schema={schema()}
      submitTitle="Complete Setup"
      links={[]}
    >
      <Input
        type="password"
        autoComplete="new-password"
        required
        name="password"
      />

      <Input
        type="password"
        autoComplete="new-password"
        required
        name="passwordConfirm"
        label="Confirm Password"
      />
    </AuthFormContainer>
  );
}

export default CompletePassword;
