import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_PARTNER_LOGO } from '@retainerclub/partner-api';

function DesktopSidebarLogo() {
  const { error, loading, data } = useQuery(GET_PARTNER_LOGO);

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="" />;
  }

  return data?.partnerLogo ? (
    <div className="m-auto">
      <img className="h-16 w-auto" src={data?.partnerLogo} alt="Partner Logo" />
    </div>
  ) : null;
}

export default DesktopSidebarLogo;
