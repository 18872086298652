import PropTypes from 'prop-types';
import PatientShowCardsDetails from './PatientShowCardsDetails';
import PatientShowCardsSettings from './PatientShowCardsSettings';

function PatientShowCards({ patient }) {
  return (
    <div className="flex flex-col gap-2 md:flex-row">
      <div className="flex basis-1 flex-col md:basis-full lg:basis-2/3">
        <PatientShowCardsSettings patient={patient} />
      </div>
      <div className="flex basis-1 flex-col md:basis-full lg:basis-1/3">
        <PatientShowCardsDetails patient={patient} />
      </div>
    </div>
  );
}

PatientShowCards.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientShowCards;
