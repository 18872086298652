import PropTypes from 'prop-types';
import { MainTableLayout } from '../../layouts';
import { stateCellRenderer, stateValueGetter } from '../../shared';

function SubscriptionsListDataTable(props) {
  const { data } = props;

  const columnDefs = [
    {
      field: 'hexCode',
      headerName: 'Subscription ID',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'uppercase',
      },
      onCellClicked: (params) => {
        params.context.navigate(`/customers/subscriptions/${params.data.slug}`);
      },
    },
    {
      field: 'name',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'state',
      headerName: 'Subscription Status',
      minWidth: 130,
      filter: 'agSetColumnFilter',
      valueGetter: stateValueGetter,
      cellRenderer: stateCellRenderer,
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'dateColumn',
      sort: 'desc',
      suppressHeaderMenuButton: true,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={data.subscriptions}
      pageTitle="Subscriptions"
    />
  );
}

SubscriptionsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SubscriptionsListDataTable;
