function userNameValueGetter(params) {
  if (
    params?.data?.userAttributes !== undefined &&
    params?.data?.userAttributes !== null
  ) {
    const name = `${params?.data?.userAttributes?.firstName} ${params?.data?.userAttributes?.lastName}`;
    return name.toLowerCase();
  }

  if (params?.data?.user !== undefined && params?.data?.user !== null) {
    const name = `${params?.data?.user?.firstName} ${params?.data?.user?.lastName}`;
    return name.toLowerCase();
  }

  return null;
}

export default userNameValueGetter;
