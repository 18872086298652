import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

function phoneCellRender(params) {
  const phoneNumber = parsePhoneNumber(params.value || '', 'US');

  return (
    <a
      href={`tel: ${params.value}`}
      target="_blank"
      rel="noreferrer"
      className="text-sm hover:text-rcprimary-400"
    >
      {phoneNumber ? formatPhoneNumber(phoneNumber.number) : params.value}
    </a>
  );
}

export default phoneCellRender;
