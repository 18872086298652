import { useQuery } from '@apollo/client';
import { LoadingOrError, TableStateSelect } from '@retainerclub/common';
import { GET_REFERRALS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReferralsListDataTable from './ReferralsListDataTable';

function ReferralsListWithActions({ hideActions = false }) {
  const localState = window.localStorage.getItem('rS');

  const [referralState, setReferralState] = useState(localState || 'recent');

  useEffect(() => {
    window.localStorage.setItem('rS', referralState);
  }, [referralState]);

  let pageTitle = 'Recent Referrals';

  if (referralState === 'sent') {
    pageTitle = 'Sent Referrals';
  }
  if (referralState === 'unsent') {
    pageTitle = 'Not Sent Referrals';
  }
  if (referralState === 'overdue') {
    pageTitle = 'Overdue Referrals';
  }
  if (referralState === 'completed') {
    pageTitle = 'Completed Referrals';
  }
  if (referralState === 'canceled') {
    pageTitle = 'Canceled Referrals';
  }

  const { error, loading, data } = useQuery(GET_REFERRALS, {
    variables: { state: referralState },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return (
    <ReferralsListDataTable
      data={data}
      pageTitle={pageTitle}
      hideFilters
      referralState={referralState}
      setReferralState={setReferralState}
      hideActions={hideActions}
      pageActions={
        <TableStateSelect value={referralState} onChange={setReferralState} />
      }
    />
  );
}

ReferralsListWithActions.propTypes = {
  hideActions: PropTypes.bool,
};

export default ReferralsListWithActions;
