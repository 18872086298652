function patientsValueGetter(params) {
  const patient = params?.data?.patientAttributes
    ? params.data.patientAttributes
    : params.data.patient;

  if (patient) {
    const name = `${patient?.firstName} ${patient?.lastName}`;
    return name.toLowerCase();
  }

  if (params?.data?.patients) {
    return params.data.patients.map((p) => {
      const name = `${p?.firstName} ${p?.lastName}`;
      return name.toLowerCase();
    });
  }

  if (params?.data?.attributes?.patients) {
    return params.data.attributes.patients.map((p) => {
      const name = p.name;
      return name.toLowerCase();
    });
  }

  return null;
}

export default patientsValueGetter;
