import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_USERS } from '@retainerclub/shared-api';
import UsersListDataTable from './UsersListDataTable';

function UsersList() {
  const { error, loading, data } = useQuery(GET_USERS);

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return <UsersListDataTable data={data} />;
}

export default UsersList;
