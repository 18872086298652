import { useApp } from '@retainerclub/hooks';
import { GET_PATIENT, UPDATE_PATIENT } from '@retainerclub/shared-api';
import {
  CheckboxInput,
  DateInput,
  Fieldset,
  Form,
  Input,
  TextArea,
} from '../../../../shared';
import CaseSettingsFormFields from '../../../CaseSettings/CaseSettingsFormFields';
import ReferralsFormPatientScanReady from '../../../Referrals/ReferralsForm/ReferralsFormPatientScanReady';
import ReferralsFormScanOptions from '../../../Referrals/ReferralsForm/ReferralsFormScanOptions';
import { initialValues, schema } from './helpers';

function PatientsForm() {
  const { isAdmin } = useApp();

  return (
    <Form
      initialValues={initialValues}
      schema={schema}
      sidebarTitle="Patient"
      sidebarDescription="A patient that is receiving treatment"
      createAction={UPDATE_PATIENT}
      updateAction={UPDATE_PATIENT}
      updateQuery={GET_PATIENT}
      afterEditLink="customers/patients"
      afterNewLink="customers/patients"
      showOnEdit
      showOnNew
      dataName="patient"
    >
      <Fieldset
        title="Patient Info"
        description="Who is receiving treatment?"
        doubleCols
      >
        <Input
          className="sm:col-span-6 xl:col-span-4"
          required
          name="firstName"
          label="Patient First Name"
        />
        <Input
          className="sm:col-span-6 xl:col-span-4"
          required
          name="lastName"
          label="Patient Last Name"
        />

        <DateInput
          dayName="birthDay"
          monthName="birthMonth"
          yearName="birthYear"
          required
          wrapperClass="col-span-full sm:col-span-6 xl:col-span-4"
        />
      </Fieldset>
      <Fieldset
        title="Scan Details"
        description="What is the status of the patient's scan?"
      >
        <div className="col-span-full mt-2 sm:col-span-4">
          <ReferralsFormScanOptions
            name="scanReady"
            usePatientAttributes={false}
          />
        </div>
        <div className="col-span-full mt-2 grid grid-cols-1 sm:col-span-2 sm:grid-cols-2">
          <ReferralsFormPatientScanReady
            usePatientAttributes={false}
            scanReadyAt="scanReadyAt"
            scanSentAt="scanSentAt"
          />
        </div>
      </Fieldset>
      <div className="col-span-full mt-4">
        <CaseSettingsFormFields />
      </div>
      <div className="col-span-full">
        <TextArea name="notes" label="Additional Notes" />
      </div>
      <div className="col-span-full mt-4 sm:col-span-3 xl:col-span-3">
        <CheckboxInput
          name="sendToClinic"
          label="Ship to Clinic"
          required
          size="lg"
          description="Select to ship the first order to the clinic."
        />
      </div>
      <div className="col-span-full mt-4 sm:col-span-3 xl:col-span-3">
        {isAdmin && (
          <CheckboxInput
            name="consumedSendToClinic"
            label="Prevent Send to Clinic"
            description="Prevent new orders from being shipped to clinic, usually because the first order has already been sent to the clinic."
            required
            size="lg"
          />
        )}
      </div>
    </Form>
  );
}

export default PatientsForm;
