import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import { Card, LineChart, Title } from '@tremor/react';
import PropTypes from 'prop-types';

function TotalByMonth({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'total_by_month' },
    notifyOnNetworkStatusChange: true,
  });

  const dataFormatter = (number) =>
    number.toLocaleString('en', {
      style: 'currency',
      currency: 'USD',
    });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <Card>
      <Title>Order Revenue (6 months)</Title>
      <LineChart
        className="mt-6"
        data={data.dashboard.metric}
        index="month"
        categories={['Total Orders']}
        colors={['emerald']}
        valueFormatter={dataFormatter}
        yAxisWidth={80}
      />
    </Card>
  ) : null;
}

TotalByMonth.propTypes = {
  variables: PropTypes.object,
};

export default TotalByMonth;
