import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import { Card } from '@tremor/react';
import PropTypes from 'prop-types';
import DashboardTileGoal from './DashboardTileGoal';

function SmileCarePlanCustomers({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'smile_care_plan_customers' },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error) {
    return (
      <Card className="relative min-h-[156px]">
        <LoadingOrError error={error} loading={loading} />
      </Card>
    );
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <DashboardTileGoal
      title="Smile Care Plan Customers"
      metric={data.dashboard.metric}
      delta="0"
      goal={100}
    />
  ) : null;
}

SmileCarePlanCustomers.propTypes = {
  variables: PropTypes.object,
};

export default SmileCarePlanCustomers;
