const awsAuthConfig = {
  development: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:ab31c7e4-4634-4f6d-9dd0-249fd83ac91f',
        userPoolId: 'us-west-2_bvydHl3IE',
        userPoolClientId: '24ogik24892l5vjp2s0ls36mlh',
      },
    },
  },
  staging: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:962652f5-ab92-44cc-864d-67a0992f9ab1',
        userPoolId: 'us-west-2_wJgeqliQX',
        userPoolClientId: '7pbrplvkq68134mvsh35ro0tg4',
      },
    },
  },
  production: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:53aa9fdc-50f6-4d42-8820-9fddd50bc62f',
        userPoolId: 'us-west-2_HuWVgAUfW',
        userPoolClientId: '6lh7ou4gv7c3tom8kh9q2g5grn',
      },
    },
  },
};

export default awsAuthConfig;
