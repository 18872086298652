import { useQuery } from '@apollo/client';
import { CircleStackIcon } from '@heroicons/react/20/solid';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import {
  AreaChart,
  Card,
  Flex,
  Icon,
  Tab,
  TabGroup,
  TabList,
  Text,
  Title,
} from '@tremor/react';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Kpis = {
  smile_care_customers: 'Smile Care Plan Customers',
  smile_care_revenue: 'Smile Care Plan Revenue',
  retainers_revenue: 'Retainers At Retail Revenue',
};

const kpiList = [
  Kpis.smile_care_customers,
  Kpis.smile_care_revenue,
  Kpis.retainers_revenue,
];

const usNumberformatter = (number, decimals = 0) =>
  Intl.NumberFormat('us', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
    .format(Number(number))
    .toString();

const formatters = {
  'Smile Care Plan Customers': (number) => `${usNumberformatter(number)}`,
  'Smile Care Plan Revenue': (number) => `$ ${(number / 100).toFixed(2)}`,
  'Retainers At Retail Revenue': (number) => `$ ${(number / 100).toFixed(2)}`,
};

function WeeklyPerformance({ variables = {} }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedKpi = kpiList[selectedIndex];

  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'weekly_performance' },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <Card>
      <>
        <div className="justify-between md:flex">
          <div>
            <Flex
              className="space-x-0.5"
              justifyContent="start"
              alignItems="center"
            >
              <Title>Performance History (Last 7 Days)</Title>
              <Icon
                icon={CircleStackIcon}
                variant="simple"
                tooltip="Shows daily increase or decrease of particular domain"
              />
            </Flex>
            <Text> Daily change per domain </Text>
          </div>
          <div>
            <TabGroup index={selectedIndex} onIndexChange={setSelectedIndex}>
              <TabList color="gray" variant="solid">
                <Tab>Smile Care Plan Customers</Tab>
                <Tab>Smile Care Plan Rev.</Tab>
                <Tab>Retainers At Retail Rev.</Tab>
              </TabList>
            </TabGroup>
          </div>
        </div>
        {/* web */}
        <div className="mt-8 hidden sm:block">
          <AreaChart
            className="mt-4 h-72 w-full"
            data={data.dashboard.metric}
            index="date"
            categories={[selectedKpi]}
            colors={['green']}
            showLegend={false}
            valueFormatter={formatters[selectedKpi]}
            yAxisWidth={56}
          />
        </div>
        {/* mobile */}
        <div className="mt-8 sm:hidden">
          <AreaChart
            className="mt-4 h-72"
            data={data.dashboard.metric}
            index="date"
            categories={[selectedKpi]}
            colors={['green']}
            showLegend={false}
            valueFormatter={formatters[selectedKpi]}
            yAxisWidth={56}
            startEndOnly
            showGradient={false}
            showYAxis={false}
          />
        </div>
      </>
    </Card>
  ) : null;
}

WeeklyPerformance.propTypes = {
  variables: PropTypes.object,
};

export default WeeklyPerformance;
