function orderItemsPatientsValueGetter(params) {
  const orderItems = params?.data?.orderItemsAttributes
    ? params.data.orderItemsAttributes
    : params.data.orderItems;

  const patients = [
    ...new Set(
      orderItems.map((orderItem) =>
        orderItem?.patient
          ? `${orderItem?.patient?.firstName} ${orderItem?.patient?.lastName}`
          : null,
      ),
    ),
  ];

  return patients.join(', ');
}

export default orderItemsPatientsValueGetter;
