import {
  BadgeDelta,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Text,
} from '@tremor/react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function DashboardTileGoal({
  title,
  deltaType = 'unchanged',
  metric,
  delta,
  goal,
  isCurrency = false,
}) {
  const USDollar = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    [],
  );

  const metricString = useMemo(() => {
    if (isCurrency) {
      return USDollar.format(metric / 100);
    }
    return metric;
  }, [USDollar, isCurrency, metric]);

  return (
    <Card>
      <Flex alignItems="start">
        <div className="truncate">
          <Text>{title}</Text>
          <Metric className="truncate">{metricString}</Metric>
        </div>
        <BadgeDelta deltaType={deltaType}>{delta}%</BadgeDelta>
      </Flex>
      <Flex className="mt-4 space-x-2">
        <Text className="truncate">{`${((metric / goal) * 100).toFixed(
          0,
        )}% (${metricString})`}</Text>
        <Text className="truncate">
          {isCurrency ? USDollar.format(goal / 100) : goal}
        </Text>
      </Flex>
      <ProgressBar value={metric / goal} className="mt-2" />
    </Card>
  );
}

DashboardTileGoal.propTypes = {
  title: PropTypes.string.isRequired,
  deltaType: PropTypes.string,
  metric: PropTypes.number.isRequired,
  delta: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  goal: PropTypes.number.isRequired,
  isCurrency: PropTypes.bool,
};

export default DashboardTileGoal;
