import { DialogTitle } from '@headlessui/react';
import { DateInput, Fieldset, Input, Modal } from '@retainerclub/common';
import PropTypes from 'prop-types';
import PatientsListAddPatientModalWrapper from './PatientsListAddPatientModalWrapper';

function PatientsListAddPatientModal({ open, handleCloseModal, accountId }) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl">
      <div>
        <div className="text-center">
          <DialogTitle
            as="h2"
            className="font-semibold text-2xl text-rcgray-900 capitalize leading-6"
          >
            Add a New Patient
          </DialogTitle>
        </div>
      </div>
      <div className="mt-4">
        <PatientsListAddPatientModalWrapper
          handleCloseModal={handleCloseModal}
          accountId={accountId}
        >
          <div className="sm:col-span-6">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
              <Fieldset
                title="Patient Info"
                description="Who is receiving treatment?"
              >
                <Input
                  className="sm:col-span-3"
                  required
                  name="firstName"
                  label="Patient First Name"
                />
                <Input
                  className="sm:col-span-3"
                  required
                  name="lastName"
                  label="Patient Last Name"
                />

                <DateInput
                  dayName="birthDay"
                  monthName="birthMonth"
                  yearName="birthYear"
                  required
                  wrapperClass="col-span-full sm:col-span-4"
                />
              </Fieldset>
            </div>
          </div>
        </PatientsListAddPatientModalWrapper>
      </div>
    </Modal>
  );
}

PatientsListAddPatientModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default PatientsListAddPatientModal;
