import { useQuery } from '@apollo/client';
import { CircleStackIcon } from '@heroicons/react/20/solid';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import {
  Flex,
  Icon,
  MultiSelect,
  MultiSelectItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from '@tremor/react';
import PropTypes from 'prop-types';
import { useState } from 'react';

function PerformanceByLocation({ variables = {}, rangeName }) {
  const [selectedLocations, setSelectedLocations] = useState([]);

  const isLocationSelected = (location) =>
    selectedLocations.includes(location.name) || selectedLocations.length === 0;

  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'performance_by_location' },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error) {
    return (
      <div className="relative min-h-[170px] bg-white">
        <LoadingOrError error={error} loading={loading} />
      </div>
    );
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <>
      <div>
        <Flex
          className="space-x-0.5"
          justifyContent="start"
          alignItems="center"
        >
          <Title>Performance History ({rangeName})</Title>
          <Icon
            icon={CircleStackIcon}
            variant="simple"
            tooltip="Shows sales performance per location"
          />
        </Flex>
      </div>
      <div className="mt-2 flex space-x-2">
        <MultiSelect
          className="max-w-full sm:max-w-xs"
          onValueChange={setSelectedLocations}
          placeholder="Select Locations..."
          value={selectedLocations}
        >
          {data.dashboard.metric.map((item) => (
            <MultiSelectItem key={item.id} value={item.name}>
              {item.name}
            </MultiSelectItem>
          ))}
        </MultiSelect>
      </div>
      <Table className="mt-6">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell className="text-right">Referrals</TableHeaderCell>
            <TableHeaderCell className="text-right">
              Smile Care Plans ($)
            </TableHeaderCell>
            <TableHeaderCell className="text-right">
              Retainers At Retail ($)
            </TableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.dashboard.metric
            .filter((item) => isLocationSelected(item))
            .map((item) => (
              <TableRow key={item.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell className="text-right">{item.referrals}</TableCell>
                <TableCell className="text-right">
                  $ {(item.smile_revenue / 100).toFixed(0)}
                </TableCell>
                <TableCell className="text-right">
                  $ {(item.retainers_revenue / 100).toFixed(0)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  ) : null;
}

PerformanceByLocation.propTypes = {
  variables: PropTypes.object,
  rangeName: PropTypes.string.isRequired,
};

export default PerformanceByLocation;
