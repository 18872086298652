function defaultValues(props) {
  const { email } = props;

  let e = '';

  if (email) {
    e = email;
  }

  return {
    token: '',
    password: '',
    passwordConfirm: '',
    email: e,
  };
}

export default defaultValues;
