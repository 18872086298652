function stateValueGetter(params) {
  if (params.data.state === 'unsent') {
    return 'not sent';
  }

  if (params.data.state === 'overdue1') {
    return 'overdue 1 day';
  }

  if (params.data.state === 'overdue2') {
    return 'overdue 3 days';
  }

  if (params.data.state === 'overdue3') {
    return 'overdue 5+ days';
  }

  return params?.data?.state?.replace(/_/g, ' ');
}

export default stateValueGetter;
