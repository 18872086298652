import { LanguageSelectAuth, Logo } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function AuthLayout() {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { t } = useTranslation('forms');

  function title() {
    if (location.pathname === '/new-password') {
      return 'Create A New Password';
    }

    if (location.pathname === '/forgot-password') {
      return 'Forgot Password';
    }

    if (location.pathname === '/reset-password') {
      return 'Reset Password';
    }

    return 'Sign In';
  }

  const from = location.state?.from?.pathname || '/';

  return currentUser !== null && currentUser !== undefined ? (
    <Navigate to={from} replace />
  ) : (
    <div className="min-h-full bg-[url('https://cdn.retainerclub.com/partnerbg1.jpg')]">
      <div className="flex h-[100vh] flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-md rounded-xl bg-white/80 p-16 lg:w-1/2">
          <div>
            <Logo
              width={210}
              height={64}
              style={{ margin: '0 auto' }}
              color="black"
            />
            <h3 className="mt-0 text-center text-2xl text-black tracking-wide">
              partner
            </h3>
            <h2 className="mt-4 text-center font-bold text-3xl text-black tracking-tight">
              {t(title())}
            </h2>
          </div>

          <div className="mt-12">
            <Outlet />
          </div>
          <div className="mt-8 flex justify-center">
            <LanguageSelectAuth />
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <div className="hidden">Message goes here</div>
      </div>
    </div>
  );
}

export default AuthLayout;
