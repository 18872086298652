import {
  Card,
  RcDl,
  RcLink,
  ShowDetailsSection,
  dobFormatter,
  timestampFormatter,
} from '@retainerclub/common';
import { useApp, useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';

function PatientShowCardsDetails({ patient }) {
  const { isAdmin, isPartner } = useApp();
  const { currentUser } = useAuth();

  return (
    <Card className="h-full p-4" heading="Details" wrapperClassName="h-full">
      <RcDl>
        {(isAdmin || (isPartner && !currentUser.scopeToLocation)) && (
          <ShowDetailsSection
            title={`${isAdmin ? 'Partner Location' : 'Location'}`}
          >
            <RcLink
              to={`${isPartner ? '/locations/' : '/partners/locations/'}${
                patient?.partnerLocation?.slug
              }`}
            >
              {patient?.partnerLocation?.name}
            </RcLink>
            <br />
            {patient?.partnerLocation?.address?.city},&nbsp;
            {patient?.partnerLocation?.address?.region}
          </ShowDetailsSection>
        )}
        <ShowDetailsSection title="Account #" className="sm:col-span-1">
          <RcLink
            to={`/customers/accounts/${patient?.account?.accountNumber}`}
            className="text-sm"
          >
            {patient?.account?.accountNumber}
          </RcLink>
        </ShowDetailsSection>
        {patient?.legacyId && (
          <ShowDetailsSection title="Legacy ID" className="sm:col-span-1">
            {patient?.legacyId}
          </ShowDetailsSection>
        )}
        <ShowDetailsSection title="Date of Birth" className="sm:col-span-1">
          {dobFormatter(patient?.dob)}
        </ShowDetailsSection>
        <ShowDetailsSection title="Created At" className="sm:col-span-1">
          {timestampFormatter(patient?.createdAt)}
        </ShowDetailsSection>
        <ShowDetailsSection title="Updated At" className="sm:col-span-1">
          {timestampFormatter(patient?.updatedAt)}
        </ShowDetailsSection>
      </RcDl>
    </Card>
  );
}

PatientShowCardsDetails.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientShowCardsDetails;
