function mapHref(lat, lng) {
  if (
    typeof navigator !== 'undefined' &&
    (navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1)
  ) {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
}

export default mapHref;
