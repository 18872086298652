import { Badge } from '@retainerclub/common';

function stateCellRender(params) {
  let color = 'green';

  if (
    params.value === 'sent' ||
    params.value === 'refunded' ||
    params.value === 'complete'
  ) {
    color = 'blue';
  }

  if (
    params.value === 'inactive' ||
    params.value === 'not sent' ||
    params.value === 'ready to produce' ||
    params.value === 'pending' ||
    params.value === 'no' ||
    params.value === 'unpaid' ||
    params.value === 'partially paid' ||
    params.value === 'on hold' ||
    params.value === 'processing' ||
    params.value === 'returned' ||
    params.value === 'hold' ||
    params.value === 'design hold' ||
    params.value === 'manufacturing hold' ||
    params.value === 'shipping hold'
  ) {
    color = 'yellow';
  }

  if (
    params.value === 'overdue 1 day' ||
    params.value === 'overdue 3 days' ||
    params.value === 'overdue 5+ days' ||
    params.value === 'canceled' ||
    params.value === 'cancelled' ||
    params.value === 'needs attention' ||
    params.value === 'payment_failed'
  ) {
    color = 'red';
  }

  return (
    <Badge
      color={color}
      fullWidth
      name={params.value}
      withDot={false}
      size="small"
    />
  );
}

export default stateCellRender;
