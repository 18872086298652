function initialValues(props) {
  const { record = {} } = props;

  function filterUser(user) {
    const { __typename, slug, state, ...rest } = user;
    return rest;
  }

  function filterPatient(patient) {
    const {
      __typename,
      slug,
      settingGroupOptionIds,
      settingsOverride,
      arches,
      dob,
      notes,
      includeEot,
      scanReady,
      scanReadyAt,
      scanSentAt,
      sendToClinic,
      ...rest
    } = patient;

    return { ...rest };
  }

  return {
    id: record?.id || null,
    userId: record?.userId || null,
    userAttributes: record?.userAttributes
      ? filterUser(record.userAttributes)
      : null,
    patientAttributes: record?.patientAttributes
      ? filterPatient(record.patientAttributes)
      : {
          firstName: null,
          lastName: null,
          birthDay: 'Day',
          birthMonth: 'Month',
          birthYear: null,
        },
    partnerLocationId: record?.partnerLocationId || null,
  };
}

export default initialValues;
