import { Button } from '@retainerclub/common';

function ActionsCellRenderer(params, handleOpenModal) {
  const { data } = params;

  return (
    <Button
      onClick={() => handleOpenModal(data)}
      name="Update"
      size="xs"
      className="relative rounded-md"
    />
  );
}

export default ActionsCellRenderer;
