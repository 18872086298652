import {
  Center,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Preload,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { Suspense, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import StlElement from './StlElement';
import StlLoader from './StlLoader';

function StlViewer({ scanFileUrl, containerStyle = '' }) {
  const orbit = useRef();
  const stlRef = useRef();

  const [matchedHeight, setMatchedHeight] = useState(200);
  const { ref } = useResizeObserver({
    onResize: ({ width: wid }) => {
      setMatchedHeight(wid);
    },
  });

  return (
    <div
      className={`${containerStyle}`}
      style={{ height: `${matchedHeight}px` }}
      ref={ref}
    >
      <Canvas shadows frameloop="demand">
        <Suspense fallback={<StlLoader loading />}>
          <Preload all />
          <Environment files="https://cdn.retainerclub.com/backgrounds/empty_warehouse_01_1k.hdr" />
          <PerspectiveCamera
            makeDefault
            fov={35}
            near={1}
            far={1000}
            position={[0, 0, 130]}
          />
          <Center>
            <StlElement stlRef={stlRef} scanFileUrl={scanFileUrl} />
            <OrbitControls ref={orbit} />
          </Center>
        </Suspense>
      </Canvas>
    </div>
  );
}

StlViewer.propTypes = {
  containerStyle: PropTypes.string,
  scanFileUrl: PropTypes.string.isRequired,
};

export default StlViewer;
