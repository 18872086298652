import { PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AgGridMain from '../../shared/AgGrid/AgGridMain';

function MainTableLayout({
  columnDefs,
  newTo = null,
  newTitle = null,
  defaultColDef = {
    floatingFilter: true,
  },
  masterDetail = false,
  rowData = [],
  height = 718,
  hideFilters = false,
  pageTitle = null,
  pageActions = null,
  hideHeader = false,
  detailCellRendererParams = null,
  hideActions = false,
}) {
  const { t } = useTranslation('navigation');

  return (
    <div className="flex h-full flex-col">
      {!hideHeader && (
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <h2 className="mt-2 ml-2 font-bold text-2xl text-black/70 leading-6">
              {pageTitle}
            </h2>
          </div>
          <div className="mx-4 flex flex-1 items-center justify-end">
            {!hideActions && pageActions}
          </div>
          <div className="flex items-center justify-end">
            {newTo && newTitle && (
              <NavLink
                to={newTo}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-rcprimary-400 px-2 py-1 text-center font-medium text-white text-xs outline outline-2 outline-white/0 outline-offset-0 hover:bg-rcprimary-400/0 hover:text-rcprimary-400 hover:outline-rcprimary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-rcprimary-400 focus-visible:outline-offset-2"
              >
                <PlusIcon
                  className="-ml-0.5 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                {t(`New ${newTitle}`)}
              </NavLink>
            )}
          </div>
        </div>
      )}
      <div className="sm:-mx-0 mt-4 grow">
        <AgGridMain
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          height={height}
          hideFilters={hideFilters}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
        />
      </div>
    </div>
  );
}

MainTableLayout.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array,
  newTo: PropTypes.string,
  newTitle: PropTypes.string,
  defaultColDef: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideFilters: PropTypes.bool,
  pageTitle: PropTypes.string,
  pageActions: PropTypes.node,
  hideHeader: PropTypes.bool,
  masterDetail: PropTypes.bool,
  detailCellRendererParams: PropTypes.object,
  hideActions: PropTypes.bool,
};

export default MainTableLayout;
