import { Button } from '@retainerclub/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import PatientsListAddPatientModal from './PatientsListAddPatientModal';

function PatientsListAddPatient({ accountId, refetch }) {
  const [open, setOpen] = useState(false);

  function handleOpenModal() {
    setOpen(true);
  }

  function handleCloseModal() {
    refetch();
    setOpen(false);
  }

  return (
    <>
      <Button
        onClick={handleOpenModal}
        name="New Patient"
        add
        size="xs"
        className="rounded-md"
      />
      <PatientsListAddPatientModal
        open={open}
        handleCloseModal={handleCloseModal}
        accountId={accountId}
      />
    </>
  );
}

PatientsListAddPatient.propTypes = {
  accountId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PatientsListAddPatient;
