import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

function StlElement({ stlRef = null, scanFileUrl }) {
  const geom = useLoader(STLLoader, scanFileUrl);

  return (
    <mesh ref={stlRef} rotation={[-Math.PI / 2, 0, -Math.PI / 1]}>
      <primitive object={geom} attach="geometry" />
      <meshPhysicalMaterial
        color={0xc3c3cd}
        reflectivity={0}
        clearcoat={0.15}
        metalness={0.9}
        wireframe={false}
        attach="material"
      />
    </mesh>
  );
}

StlElement.propTypes = {
  stlRef: PropTypes.object,
  scanFileUrl: PropTypes.string.isRequired,
};

export default StlElement;
