import * as Yup from 'yup';

const YEAR_REGEX = /(19|20)\d{2}/;

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
    birthDay: Yup.string().required('day of birth is required'),
    birthMonth: Yup.string().required('birth month is required'),
    birthYear: Yup.string()
      .required('birth year is required')
      .matches(YEAR_REGEX, 'year must be between 1900 and 2099'),
    settingGroupOptionIds: Yup.array().of(Yup.string()).nullable(true),
    settingsOverride: Yup.boolean(),
    notes: Yup.string().nullable(true),
    includeEot: Yup.boolean(),
    scanReady: Yup.boolean().required('scan details are required'),
    scanReadyAt: Yup.string().nullable(true),
    scanSentAt: Yup.string().nullable(true),
    sendToClinic: Yup.boolean().required('ship to clinic is required'),
    consumedSendToClinic: Yup.boolean().required(
      'consumed send to clinic is required',
    ),
  });
}

export default schema;
