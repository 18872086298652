import { useAuth } from '@retainerclub/hooks';
import { EmailInput, Input } from '../../../shared';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { initialValues, schema } from './helpers';

function ResetPassword() {
  const { reset, tempCredentials } = useAuth();

  return (
    <AuthFormContainer
      submitFunction={reset}
      initialValues={initialValues(tempCredentials)}
      schema={schema()}
      submitTitle="Reset Password"
      links={[
        { id: 1, to: '/forgot-password', text: 'Request a new reset code' },
        { id: 2, to: '/signin', text: 'Sign In', preText: 'Return to ' },
      ]}
    >
      <>
        {(!tempCredentials?.email || tempCredentials?.email === '') && (
          <EmailInput autoComplete="email" required />
        )}

        <Input type="text" autoComplete="one-time-code" required name="token" />

        <Input
          type="password"
          autoComplete="new-password"
          required
          name="password"
        />

        <Input
          type="password"
          autoComplete="new-password"
          required
          name="passwordConfirm"
          label="Confirm Password"
        />
      </>
    </AuthFormContainer>
  );
}

export default ResetPassword;
