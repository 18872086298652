import { useQuery } from '@apollo/client';
import { GET_PARTNER_LOC_ID } from '@retainerclub/shared-api';
import { useParams } from 'react-router-dom';
import { LoadingOrError } from '../../../../../shared';
import CaseSettingsFormInner from './CaseSettingsFormInner';

function CaseSettingsForm() {
  const { slug } = useParams();

  const { error, loading, data } = useQuery(GET_PARTNER_LOC_ID, {
    variables: { slug },
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Case Settings" />
    );
  }

  return data?.partnerLocation ? (
    <CaseSettingsFormInner partnerLocation={data.partnerLocation} />
  ) : null;
}

export default CaseSettingsForm;
