import * as Yup from 'yup';

const PASSWORD_REGEX =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

function schema() {
  return Yup.object().shape({
    email: Yup.string().email(),
    token: Yup.string().min(6).required('Verification token is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        PASSWORD_REGEX,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
    passwordConfirm: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password'), null], "Passwords don't match."),
  });
}

export default schema;
