import { useQuery } from '@apollo/client';
import { GET_REFERRAL } from '@retainerclub/shared-api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LoadingOrError,
  ShowActivity,
  ShowHeader,
  ShowPatients,
} from '../../../shared';
import ReferralShowActions from './ReferralShowActions';
import ShowBodyReferral from './ShowBodyReferral';

function ReferralsShow() {
  const { slug } = useParams();
  const navigation = useNavigate();

  const { data, loading, error } = useQuery(GET_REFERRAL, {
    variables: { slug },
  });

  if (slug === null || slug === undefined) {
    navigation('/partners/organizations');
  }

  if (loading || error) {
    return (
      <main className="relative min-h-screen">
        <LoadingOrError error={error} loading title="Referral" />
      </main>
    );
  }

  return (
    <>
      <ShowHeader
        title="Referral"
        upcaseHeader
        name={data?.referral?.slug}
        state={data?.referral?.state}
        editTo={`/customers/referrals/${data?.referral?.slug}/edit`}
        listTo="/customers/referrals"
        actions={<ReferralShowActions referral={data?.referral} />}
      />
      <ShowBodyReferral referral={data?.referral} />

      <ShowPatients
        patients={
          data?.referral?.patientAttributes
            ? [data?.referral?.patientAttributes]
            : []
        }
        title="Patient"
      />

      <ShowActivity id={data?.referral?.id} klass="Referral" />
    </>
  );
}

export default ReferralsShow;
