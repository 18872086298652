import {
  EnvelopeIcon,
  ExclamationCircleIcon,
  HandRaisedIcon,
  HomeIcon,
  NoSymbolIcon,
  ShoppingCartIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

const ICON_COLOR = 'text-rcprimary-400';

function patientIcon() {
  return (
    <img
      src="https://cdn.retainerclub.com/images/icons/rc/RC_Icons_Stroke_HappyPatients.svg"
      alt="check"
      className="mr-[-4px] size-8"
    />
  );
}

function clockIcon() {
  return (
    <img
      src="https://cdn.retainerclub.com/images/icons/rc/RC_Icons_Stroke_Clock.svg"
      alt="check"
      className="mr-[-3px] size-6"
    />
  );
}

function dashboardIcon() {
  return (
    <img
      src="https://cdn.retainerclub.com/images/icons/rc/RC_Icons_Stroke_Dashboard.svg"
      alt="check"
      className="mr-[-3px] size-6"
    />
  );
}

function salesIcon() {
  return (
    <img
      src="https://cdn.retainerclub.com/images/icons/rc/RC_Icons_Stroke_MoneySymbol.svg"
      alt="check"
      className="mr-[-4px] size-8"
    />
  );
}

const navLinks = [
  {
    name: 'Home',
    icon: HomeIcon,
    children: [
      {
        name: 'Dashboard',
        to: '/',
        icon: dashboardIcon,
        iconColor: ICON_COLOR,
      },
      {
        name: 'Referrals',
        to: '/customers/referrals',
        icon: EnvelopeIcon,
        iconColor: ICON_COLOR,
      },
    ],
  },
  {
    name: 'Patients',
    icon: patientIcon,
    children: [
      {
        name: 'All Patients',
        to: '/customers/patients',
        icon: UsersIcon,
        iconColor: ICON_COLOR,
      },
      {
        name: 'Needs Attention',
        to: '/customers/patients/needs-attention',
        icon: ExclamationCircleIcon,
        iconColor: 'text-rccallouts-danger',
        textColor: 'text-rccallouts-danger',
      },
      {
        name: 'Awaiting Scan',
        to: '/customers/patients/awaiting-scan',
        icon: NoSymbolIcon,
        iconColor: 'text-rccallouts-warning',
      },
      {
        name: 'Never Purchased',
        to: '/customers/patients/never-purchased',
        icon: HandRaisedIcon,
        iconColor: ICON_COLOR,
      },
    ],
  },
  {
    name: 'Sales',
    icon: salesIcon,
    children: [
      {
        name: 'All Purchases',
        to: '/customers/orders',
        icon: ShoppingCartIcon,
        iconColor: ICON_COLOR,
      },
      {
        name: 'Production',
        to: '/customers/orders/production',
        icon: TruckIcon,
        iconColor: ICON_COLOR,
      },
      {
        name: 'Future Fulfillment',
        to: '/customers/orders/future-fulfillment',
        icon: clockIcon,
        iconColor: ICON_COLOR,
      },
    ],
  },
];

export default navLinks;
