function initialValues(props) {
  const { record = {} } = props;

  return {
    accountId: record?.accountId || null,
    firstName: record?.firstName || '',
    lastName: record?.lastName || '',
    birthDay: record?.birthDay || 'Day',
    birthMonth: record?.birthMonth || 'Month',
    birthYear: record?.birthYear || null,
  };
}

export default initialValues;
