import { CheckIcon } from '@heroicons/react/20/solid';
import { Card, RcDl, ShowDetailsSection } from '@retainerclub/common';
import PropTypes from 'prop-types';

function PatientDesignNotesShowCard({ patient }) {
  const pGroups = patient?.settingGroupOptions?.map((o) => o.settingGroup);
  const foundGroups = pGroups?.length > 0 && [...new Set(pGroups)];

  return (
    (patient?.settingGroupOptions.length > 0 || patient?.notes) && (
      <div className="flex flex-col">
        <div className="flex basis-1 flex-col md:basis-full lg:basis-2/3">
          <Card
            className="h-full p-4"
            heading="Design Notes"
            wrapperClassName="h-full"
          >
            <div className="mt-4" />
            <RcDl dividers={false} gridCols="sm:grid-cols-2 gap-y-4 gap-x-8">
              {foundGroups?.length > 0 &&
                foundGroups?.map((sg) => (
                  <ShowDetailsSection
                    title={`${sg.name}:`}
                    horizontal={false}
                    key={sg.id}
                  >
                    <div className="mt-2">
                      {patient?.settingGroupOptions.map((o) => {
                        if (o.settingGroup.id === sg.id) {
                          return (
                            <div className="my-2" key={o.id}>
                              <CheckIcon className="mr-1 inline size-5 text-rcprimary-400" />
                              &nbsp;
                              {o.name}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </ShowDetailsSection>
                ))}
              {patient?.notes && (
                <ShowDetailsSection
                  title="Additional Notes:"
                  horizontal={false}
                >
                  <div className="mt-2">{patient?.notes}</div>
                </ShowDetailsSection>
              )}
            </RcDl>
          </Card>
        </div>
      </div>
    )
  );
}

PatientDesignNotesShowCard.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientDesignNotesShowCard;
