import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import DesktopSidebarLogo from './DesktopSideBarLogo';
import navLinks from './helpers/navLinks';

function DesktopSidebar() {
  return (
    <div className="z-30 hidden h-full bg-white/80 pt-[68px] shadow-md lg:fixed lg:inset-y-0 lg:flex lg:w-60 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-black/10 border-r p-6">
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navLinks.map((item) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <NavLink
                        to={item.to}
                        className={({ isActive }) =>
                          `${
                            isActive
                              ? 'border-rcprimary-400 text-rcprimary-400 focus:ring-0'
                              : 'border-white/0 text-black focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-rcprimary-400'
                          } flex w-full items-center gap-x-3 rounded-md border-2 p-2 text-left font-bold text-sm leading-6 hover:border-2 hover:border-rcprimary-400 hover:text-rcprimary-400 focus:outline-none`
                        }
                      >
                        <item.icon className="size-5" />
                        {item.name}
                      </NavLink>
                    ) : (
                      <Disclosure as="div" defaultOpen>
                        <>
                          <DisclosureButton className="group flex w-full items-center gap-x-3 rounded-md border-2 border-black/0 p-2 text-left font-bold text-black text-sm leading-6 hover:border-rcprimary-400 hover:text-rcprimary-400">
                            <item.icon
                              className="size-5 shrink-0 text-black group-hover:text-rcprimary-400"
                              aria-hidden="true"
                            />
                            {item.name}
                          </DisclosureButton>
                          <DisclosurePanel as="ul" className="mt-1 px-2" static>
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <DisclosureButton
                                  as={NavLink}
                                  to={subItem.to}
                                  className={`block rounded-md py-1 pr-2 pl-4 font-medium text-rcgray-950 text-sm capitalize leading-6 hover:bg-rcgray-200 hover:text-black ${
                                    subItem.icon
                                      ? 'flex w-full items-center gap-x-3'
                                      : ''
                                  }`}
                                >
                                  {subItem.icon && (
                                    <subItem.icon
                                      className={`size-5 ${subItem.iconColor}`}
                                    />
                                  )}

                                  <span
                                    className={`${
                                      subItem?.textColor
                                        ? subItem.textColor
                                        : 'text-black'
                                    }`}
                                  >
                                    {subItem.name}
                                  </span>
                                </DisclosureButton>
                              </li>
                            ))}
                          </DisclosurePanel>
                        </>
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>

            <li className="mt-auto">&nbsp;</li>
          </ul>
        </nav>
        <DesktopSidebarLogo />
      </div>
    </div>
  );
}

export default DesktopSidebar;
