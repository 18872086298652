import { getDefaultBooleanValue } from '../../../../../transforms';

function initialValues({ record = {} }) {
  return {
    id: record?.id || null,
    firstName: record?.firstName || '',
    lastName: record?.lastName || '',
    birthDay: record?.birthDay || 'Day',
    birthMonth: record?.birthMonth || 'Month',
    birthYear: record?.birthYear || null,
    settingsOverride: getDefaultBooleanValue(record?.settingsOverride, false),
    settingGroupOptionIds: Array.isArray(record?.settingGroupOptionIds)
      ? record.settingGroupOptionIds
      : [],
    notes: record?.notes || null,
    includeEot: getDefaultBooleanValue(record?.includeEot, true),
    scanReadyAt: record?.scanReadyAt || null,
    scanSentAt: record?.scanSentAt || null,
    scanReady: getDefaultBooleanValue(record?.scanReady, false),
    sendToClinic: getDefaultBooleanValue(record?.sendToClinic, false),
    consumedSendToClinic: getDefaultBooleanValue(
      record?.consumedSendToClinic,
      false,
    ),
  };
}

export default initialValues;
