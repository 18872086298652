import { useQuery } from '@apollo/client';
import { LoadingOrError, TableStateSelect } from '@retainerclub/common';
import { GET_ORDERS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import OrdersListDataTable from './OrdersListDataTable';

function OrdersList({ height = 718 }) {
  const [orderState, setOrderState] = useState('all');

  let pageTitle = 'All Purchases';

  if (orderState?.futureFulfillment === true) {
    pageTitle = 'Future Fullfillment';
  }

  const { error, loading, data } = useQuery(GET_ORDERS, {
    variables: { futureFulfillment: orderState === 'future' },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return (
    <OrdersListDataTable
      data={data}
      pageTitle={pageTitle}
      height={height}
      pageActions={
        <TableStateSelect
          value={orderState}
          onChange={setOrderState}
          states={[
            { id: 'all', name: 'All Purchases' },
            { id: 'future', name: 'Future Fulfillment' },
          ]}
        />
      }
    />
  );
}

OrdersList.propTypes = {
  height: PropTypes.number,
};

export default OrdersList;
