import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    id: Yup.string().required('id is required'),
    settingGroupOptionIds: Yup.array().of(Yup.string()).nullable(true),
  });
}

export default schema;
