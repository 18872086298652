import { DynamicSelect, Fieldset } from '@retainerclub/common';
import { useAuth, useFormValues } from '@retainerclub/hooks';
import {
  GET_PARTNER_LOCS_SELECT,
  GET_PARTNER_ORGS_SELECT,
} from '@retainerclub/shared-api';

function ReferralsFormPartner() {
  const { partnerOrganizationId } = useFormValues();
  const { currentUser } = useAuth();

  const app = import.meta.env.VITE_APP_NAME;
  let variables = {};

  if (app.includes('admin')) {
    variables = { partnerOrganizationId };
  }

  return app.includes('admin') ||
    (app.includes('partner') && !currentUser.scopeToLocation) ? (
    <Fieldset
      title={`${
        app.includes('admin') ? 'Partner Details' : 'Location Assignment'
      }`}
      description={`${
        app.includes('admin')
          ? 'Partner assignment for this referral'
          : 'Which location is this attributed to?'
      }`}
    >
      <>
        {app.includes('admin') && (
          <DynamicSelect
            className="sm:col-span-6"
            dynamicQuery={GET_PARTNER_ORGS_SELECT}
            dataName="partnerOrganizations"
            required
            name="partnerOrganizationId"
            label="Partner Organization"
            disableOnEdit
            searchable
          />
        )}

        {(app.includes('admin') &&
          partnerOrganizationId !== null &&
          partnerOrganizationId !== undefined) ||
        app.includes('partner') ? (
          <DynamicSelect
            className="sm:col-span-6"
            dynamicQuery={GET_PARTNER_LOCS_SELECT}
            dataName="partnerLocations"
            required
            name="partnerLocationId"
            label="Partner Location"
            variables={variables}
            disableOnEdit
            searchable
          />
        ) : null}
      </>
    </Fieldset>
  ) : null;
}

export default ReferralsFormPartner;
