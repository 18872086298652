import { timestampFormatter } from '@retainerclub/common';
import PropTypes from 'prop-types';
import PatientShowScansTableRowActions from './PatientShowScansTableRowActions';

function PatientShowScansTableRow({ scanFile, refetch, handleOpenModal }) {
  return (
    <tr>
      <td className="whitespace-nowrap px-3 py-2 font-medium text-gray-900 text-xs">
        {scanFile.originalFilename}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-gray-500 text-xs">
        {scanFile.arches}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-gray-500 text-xs">
        {timestampFormatter(scanFile.scannedAt)}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-gray-500 text-xs">
        {timestampFormatter(scanFile.createdAt)}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-right text-gray-500 text-xs">
        <PatientShowScansTableRowActions
          scanFile={scanFile}
          refetch={refetch}
          handleOpenModal={handleOpenModal}
        />
      </td>
    </tr>
  );
}

PatientShowScansTableRow.propTypes = {
  scanFile: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default PatientShowScansTableRow;
