import { useApp, useAuth, useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { MainTableLayout } from '../../layouts';
import {
  imageCellRenderer,
  includeEotCellRenderer,
  orderItemsPatientsCellRenderer,
  orderItemsPatientsValueGetter,
  orderItemsValueGetter,
  patientsValueGetter,
  paymentStateValueGetter,
  stateCellRenderer,
  stateValueGetter,
} from '../../shared';

function OrdersListDataTable({
  data,
  pageTitle = null,
  height = 718,
  pageActions = null,
  hideActions = false,
}) {
  const { locale } = useLocale();
  const { isAdmin, isPartner } = useApp();
  const { currentUser } = useAuth();

  const locationUrl = useMemo(
    () => (isPartner ? '/locations/' : '/partners/locations/'),
    [isPartner],
  );

  const columnDefs = [
    {
      field: 'hexCode',
      headerName: 'Order ID',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'uppercase',
      },
      cellRenderer: 'agGroupCellRenderer',
      onCellClicked: (params) => {
        params.context.navigate(`/customers/orders/${params.data.slug}`);
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'dateColumn',
      sort: 'desc',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'orderItems',
      headerName: 'Patient(s)',
      filter: 'agTextColumnFilter',
      minWidth: 160,
      valueGetter: orderItemsPatientsValueGetter,
      cellRenderer: orderItemsPatientsCellRenderer,
    },
    {
      field: 'orderItems',
      headerName: 'Items Ordered',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      valueGetter: orderItemsValueGetter,
    },
    {
      field: 'partnerLocation.name',
      headerName: isPartner ? 'Location' : 'Partner',
      minWidth: 200,
      filter: 'agSetColumnFilter',
      cellStyle: { cursor: 'pointer' },
      onCellClicked: (params) => {
        params.context.navigate(
          `${locationUrl}${params.data.partnerLocation.slug}`,
        );
      },
      hide: currentUser.roleLevel !== 0,
    },
    {
      field: 'state',
      headerName: 'Order Status',
      minWidth: 170,
      filter: 'agSetColumnFilter',
      valueGetter: stateValueGetter,
      cellRenderer: stateCellRenderer,
    },
    {
      field: 'paymentState',
      headerName: 'Payment Status',
      minWidth: 170,
      filter: 'agSetColumnFilter',
      valueGetter: paymentStateValueGetter,
      cellRenderer: stateCellRenderer,
    },
    {
      field: 'paidByPartner',
      headerName: 'Paid in Office?',
      minWidth: 150,
      filter: 'agSetColumnFilter',
      valueGetter: (params) => (params?.data?.paidByPartner ? 'yes' : 'no'),
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'total',
      headerName: 'Total Charged',
      columnGroupShow: 'closed',
      minWidth: 150,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'toplineRevenue',
      headerName: 'Topline Rev',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'earnedRevenue',
      headerName: 'Earned Rev',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'partnerRevenuePlatform',
      headerName: 'Partner Rev (Platform)',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'partnerRevenueOffice',
      headerName: 'Partner Rev (Office)',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'partnerRevenueTotal',
      headerName: 'Partner Rev (Total)',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'partnerFee',
      headerName: 'RC Partner Fees',
      columnGroupShow: 'open',
      minWidth: 120,
      hide: !isAdmin,
      valueFormatter: (params) =>
        (params.value / 100).toLocaleString(locale, {
          style: 'currency',
          currency: 'USD',
        }),
    },
  ];

  const detailCellRendererParams = {
    detailGridOptions: {
      columnDefs: [
        {
          field: 'featuredImage',
          headerName: '',
          cellStyle: { padding: '2px 2px', textAlign: 'center' },
          width: 65,
          suppressSizeToFit: true,
          suppressHeaderMenuButton: true,
          resizable: false,
          filter: false,
          sortable: false,
          cellRenderer: imageCellRenderer,
        },
        { field: 'name', headerName: 'Product' },
        {
          field: 'patient',
          headerName: 'Patient',
          valueGetter: patientsValueGetter,
          cellStyle: { textTransform: 'capitalize' },
        },
        {
          field: 'patient.scanReady',
          headerName: 'Scan Ready?',
          minWidth: 130,
          filter: 'agSetColumnFilter',
          valueGetter: (params) =>
            params?.data?.patient?.scanReady ? 'yes' : 'no',
          cellRenderer: includeEotCellRenderer,
        },
        {
          field: 'patient.scanReadyAt',
          headerName: 'Scan Ready Date',
          minWidth: 130,
          type: 'dateColumn',
          suppressHeaderMenuButton: true,
        },
        { field: 'quantity' },
        {
          field: 'discountAmount',
          headerName: 'Discounts',
          valueFormatter: (params) =>
            (params.value / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          field: 'shippingAmount',
          headerName: 'Shipping',
          valueFormatter: (params) =>
            (params.value / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          field: 'taxAmount',
          headerName: 'Tax',
          valueFormatter: (params) =>
            (params.value / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          field: 'price',
          headerName: 'Price',
          valueFormatter: (params) =>
            (params.value / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            }),
        },
        {
          field: 'total',
          headerName: 'Total',
          valueFormatter: (params) =>
            (params.value / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            }),
        },
      ],
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.orderItems);
    },
  };

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={data?.orders || []}
      pageTitle={pageTitle}
      detailCellRendererParams={detailCellRendererParams}
      masterDetail
      height={height}
      pageActions={pageActions}
      hideActions={hideActions}
    />
  );
}

OrdersListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageActions: PropTypes.node,
  hideActions: PropTypes.bool,
};

export default OrdersListDataTable;
