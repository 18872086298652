import PropTypes from 'prop-types';
import { useState } from 'react';
import Body from './Body/Body';
import DesktopSidebar from './DesktopSidebar/DesktopSidebar';
import MobileSidebar from './MobileSidebar/MobileSidebar';
import Topbar from './Topbar/Topbar';

function MainLayout(props) {
  const { children } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Topbar setSidebarOpen={setSidebarOpen} />
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <DesktopSidebar />
      <Body>{children}</Body>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
