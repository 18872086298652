import {
  AppInner,
  CaseSettingsForm,
  CompletePassword,
  ErrorPage,
  ForgotPassword,
  OrdersShow,
  PartnerUsersForm,
  PartnerUsersShow,
  PatientsForm,
  Profile,
  ReferralsShow,
  ResetPassword,
  SignIn,
  UsersForm,
} from '@retainerclub/common';
import { GET_ACCOUNT } from '@retainerclub/partner-api';
import {
  AccountsShow,
  AuthLayout,
  PartnerLocsShow,
  PatientsShow,
  ReferralsForm,
  RequireAuth,
  UsersShow,
} from '@retainerclub/partner-shared';
import {
  AccountsList,
  OrdersList as OrdersListShared,
  PartnerLocsList,
  PartnerUsersList,
  PatientsList,
  ReferralsListWithActions,
  ReferralsProductionList,
  UsersList,
} from '@retainerclub/tables';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { GET_ACCOUNTS_CHEWY } from '@retainerclub/shared-api';
import Dashboard from './views/Dashboard/Dashboard';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppInner />} errorElement={<ErrorPage />}>
        <Route element={<RequireAuth />}>
          <Route element={<Dashboard />} path="/" />

          <Route element={<Profile />} path="/profile" />

          <Route
            element={<PartnerUsersList newTo="/users/new" hideOrg />}
            path="/users"
          />
          <Route element={<PartnerUsersForm />} path="/users/new" />
          <Route element={<PartnerUsersShow />} path="/users/:slug" />
          <Route element={<PartnerUsersForm />} path="/users/:slug/edit" />

          <Route element={<PartnerLocsList newTo={null} />} path="/locations" />
          <Route
            element={<PartnerLocsShow newTo={null} />}
            path="/locations/:slug"
          />
          <Route
            element={<CaseSettingsForm />}
            path="locations/:slug/case-settings"
          />

          <Route element={<Outlet />} path="/customers">
            <Route
              element={<AccountsList query={GET_ACCOUNTS_CHEWY} />}
              path="accounts"
            />
            <Route
              element={<AccountsShow query={GET_ACCOUNT} />}
              path="accounts/:slug"
            />

            <Route element={<OrdersListShared />} path="orders" />
            <Route
              element={
                <OrdersListShared
                  pageTitle="Production"
                  variables={{
                    states: [
                      'being_manufactured',
                      'preparing_shipment',
                      'partially_shipped',
                      'shipped',
                      'on_hold',
                      'processing',
                    ],
                  }}
                />
              }
              path="orders/production"
            />
            <Route
              element={
                <OrdersListShared
                  pageTitle="Future Fulfillment"
                  variables={{ futureFulfillment: true }}
                />
              }
              path="orders/future-fulfillment"
            />
            <Route element={<OrdersShow />} path="orders/:slug" />

            <Route element={<PatientsList />} path="patients" />
            <Route
              element={<ReferralsProductionList pageTitle="Needs Attention" />}
              path="patients/needs-attention"
            />
            <Route
              element={<ReferralsProductionList pageTitle="Needs Attention" />}
              path="patients/needs-attention/:slug"
            />
            <Route
              element={
                <ReferralsProductionList
                  pageTitle="Awaiting Scan"
                  variables={{
                    withoutScan: true,
                    activeAccount: true,
                  }}
                />
              }
              path="patients/awaiting-scan"
            />
            <Route
              element={
                <ReferralsProductionList
                  pageTitle="Never Purchased"
                  variables={{
                    withoutOrders: true,
                    withoutScan: false,
                    activeAccount: true,
                  }}
                />
              }
              path="patients/never-purchased"
            />
            <Route element={<PatientsShow />} path="patients/:slug" />
            <Route element={<PatientsForm />} path="patients/:slug/edit" />

            <Route element={<UsersList />} path="users" />
            <Route element={<UsersShow />} path="users/:slug" />
            <Route element={<UsersForm />} path="users/:slug/edit" />

            <Route element={<Outlet />} path="referrals">
              <Route element={<ReferralsListWithActions />} index />
              <Route element={<ReferralsForm />} path="new" />
              <Route element={<ReferralsShow />} path=":slug" />
              <Route element={<ReferralsForm />} path=":slug/edit" />
            </Route>
          </Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route element={<SignIn />} path="/signin" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<CompletePassword />} path="/new-password" />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
