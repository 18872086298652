import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_ORDERS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import OrdersListDataTable from './OrdersListDataTable';

function OrdersList({
  variables = { futureFulfillment: false },
  pageTitle = 'All Purchases',
  hideActions = false,
  height = 718,
}) {
  const { error, loading, data } = useQuery(GET_ORDERS, {
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return (
    <OrdersListDataTable
      data={data}
      pageTitle={pageTitle}
      height={height}
      hideActions={hideActions}
    />
  );
}

OrdersList.propTypes = {
  variables: PropTypes.object,
  pageTitle: PropTypes.string,
  hideActions: PropTypes.bool,
  height: PropTypes.number,
};

export default OrdersList;
