import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
    email: Yup.string().email().required('email is required'),
    phone: Yup.string(),
    language: Yup.string().required('language is required'),
    imageUrl: Yup.string().nullable(true),
    roleTypeId: Yup.string().required('role is required'),
    partnerOrganizationId: Yup.string().nullable(true),
    partnerLocationId: Yup.string().nullable(true),
  });
}

export default schema;
