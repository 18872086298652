import PropTypes from 'prop-types';
import MainTableLayout from '../../layouts/MainTableLayout/MainTableLayout';
import {
  dobValueGetter,
  eotValueGetter,
  includeEotCellRenderer,
  madePurchaseValueGetter,
  nameValueGetter,
} from '../../shared';
import ActionsCellRenderer from './ActionsCellRenderer';

function ReferralsProductionListDataTable({
  records = [],
  pageTitle = null,
  handleOpenModal,
  hideActions,
}) {
  const columnDefs = [
    {
      headerName: 'Patient',
      suppressHeaderMenuButton: true,
      minWidth: 150,
      valueGetter: nameValueGetter,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: 'bold',
      },
      onCellClicked: (params) => {
        params.context.navigate(`/customers/patients/${params.data.slug}`);
      },
    },
    {
      field: 'DOB',
      minWidth: 120,
      valueGetter: dobValueGetter,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Account Holder',
      minWidth: 150,
      suppressHeaderMenuButton: true,
      flex: 1,
      filter: 'agTextColumnFilter',
      valueGetter: (params) =>
        `${params?.data?.account?.users[0]?.firstName} ${params?.data?.account?.users[0]?.lastName}`,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
      },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/users/${params?.data?.account?.users[0]?.slug}`,
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'dateColumn',
      sort: 'desc',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'includeEot',
      headerName: 'EOT Purchased?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: eotValueGetter,
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'orders',
      headerName: 'Made Purchase?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: madePurchaseValueGetter,
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'settingsOverride',
      headerName: 'Custom Lab Notes?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: (params) => (params?.data?.settingsOverride ? 'yes' : 'no'),
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'patientAttributes.scanReady',
      headerName: 'Scan Available?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: (params) => (params?.data?.scanReady ? 'yes' : 'no'),
      cellRenderer: includeEotCellRenderer,
    },
    {
      headerName: 'Case Details',
      suppressHeaderMenuButton: true,
      minWidth: 112,
      hide: hideActions,
      cellRenderer: (params) => ActionsCellRenderer(params, handleOpenModal),
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={records}
      hideFilters
      pageTitle={pageTitle}
      defaultColDef={{ autoHeaderHeight: true }}
    />
  );
}

ReferralsProductionListDataTable.propTypes = {
  records: PropTypes.array,
  pageTitle: PropTypes.string,
  handleOpenModal: PropTypes.func.isRequired,
  hideActions: PropTypes.bool.isRequired,
};

export default ReferralsProductionListDataTable;
