function emailCellRender(params) {
  return (
    <a
      href={`mailto: ${params.value}`}
      target="_blank"
      rel="noreferrer"
      className="text-sm hover:text-rcprimary-400"
    >
      {params.value}
    </a>
  );
}

export default emailCellRender;
