function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    productIds: [],
    includeEot:
      record?.includeEot !== null && record?.includeEot !== undefined
        ? record?.includeEot
        : false,
  };
}

export default initialValues;
