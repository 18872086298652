function imageCellRender(params) {
  const src =
    params.value || 'https://cdn.retainerclub.com/images/defaults/default.svg';

  return (
    <div
      className="inline-block size-9 rounded-full bg-cover"
      style={{
        backgroundImage: `url(${src})`,
        margin: '0 auto',
      }}
    />
  );
}

export default imageCellRender;
