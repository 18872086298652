function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    firstName: record?.firstName || null,
    lastName: record?.lastName || null,
    email: record?.email || null,
    phone: record?.phone || null,
    language: record?.language || 'en',
    imageUrl: record?.imageUrl || null,
    roleTypeId: record?.roleTypeId || null,
    partnerOrganizationId: record?.partnerOrganizationId || null,
    partnerLocationId: record?.partnerLocationId || null,
  };
}

export default initialValues;
