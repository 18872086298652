import { useQuery } from '@apollo/client';
import { TabGroup, TabList, TabPanel } from '@headlessui/react';
import {
  LoadingOrError,
  RcTab,
  RcTabPanels,
  ShowActivity,
  ShowCustomerAddresses,
  ShowCustomerUsers,
  ShowHeader,
  ShowSubscriptions,
} from '@retainerclub/common';
import { OrdersList, PatientsList, ReferralsList } from '@retainerclub/tables';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
function AccountsShow({ query }) {
  const { slug } = useParams();
  const navigation = useNavigate();

  const { data, loading, error, refetch } = useQuery(query, {
    variables: { slug },
    notifyOnNetworkStatusChange: true,
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation('/customers/accounts');
  }

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Account" />;
  }

  return (
    <>
      <ShowHeader
        title="Account"
        name={`${data?.account?.accountNumber}`}
        listTo="/customers/accounts"
      />
      <div className="flex flex-col gap-x-2 md:flex-row">
        <div className="mb-4 flex basis-1 flex-col sm:mb-0 md:basis-1/3">
          <ShowCustomerUsers users={data?.account?.users} />
        </div>
        <div className="mb-4 flex basis-1 flex-col sm:mb-0 md:basis-1/3">
          <ShowCustomerAddresses
            accountAddresses={data?.account?.accountAddresses}
            refetch={refetch}
            accountId={data?.account?.id}
          />
        </div>
        <div className="flex basis-1 flex-col sm:mb-0 md:basis-1/3">
          <ShowSubscriptions />
        </div>
      </div>
      <TabGroup as="div">
        <div className="-mx-4 flex overflow-x-auto sm:mx-0">
          <div className="ml-4 flex-auto sm:px-0">
            <TabList className="flex space-x-2">
              <RcTab name="Patients" />
              <RcTab name="Referrals" />
              <RcTab name="Purchases" />
            </TabList>
          </div>
        </div>
        <RcTabPanels height="sm">
          <TabPanel>
            <PatientsList
              variables={{ accountId: data?.account?.id }}
              height={300}
              pageTitle={null}
              showAddPatient={true}
            />
          </TabPanel>
          <TabPanel>
            <ReferralsList
              variables={{ accountId: data?.account?.id }}
              height={300}
              pageTitle={null}
              hideHeader
            />
          </TabPanel>
          <TabPanel>
            <OrdersList
              variables={{ accountId: data?.account?.id }}
              height={300}
              pageTitle={null}
            />
          </TabPanel>
        </RcTabPanels>
      </TabGroup>

      <ShowActivity id={data?.account?.id} klass="Account" />
    </>
  );
}

AccountsShow.propTypes = {
  query: PropTypes.object.isRequired,
};

export default AccountsShow;
