import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    email: Yup.string().email().required('email is required'),
    password: Yup.string().required('Password is required'),
  });
}

export default schema;
