import { Badge } from '@retainerclub/common';

function activeCellRender(params) {
  let color = 'green';

  if (params.value === 'inactive' || params.value?.toLowerCase() === 'no') {
    color = 'yellow';
  }

  return (
    <Badge
      color={color}
      fullWidth
      name={params.value}
      withDot={false}
      size="small"
    />
  );
}

export default activeCellRender;
