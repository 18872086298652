import { useQuery } from '@apollo/client';
import { GET_ORDER } from '@retainerclub/shared-api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LoadingOrError,
  ShowActivity,
  ShowHeader,
  ShowReferrals,
} from '../../../shared';
import OrderShowActions from './OrderShowActions';
import OrderShowBody from './OrderShowBody';
import OrderShowRevenue from './OrderShowRevenue';
import OrdersShowProductDetails from './OrdersShowProductDetails';

function OrdersShow() {
  const { slug } = useParams();
  const navigation = useNavigate();

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: { slug },
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation('/customers/orders');
  }

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Order" />;
  }

  return (
    <>
      <ShowHeader
        title="Order Number"
        name={`${data.order.hexCode.toUpperCase()}`}
        listTo="/customers/orders"
        datePlaced={data.order.createdAt}
        total={data.order.total}
        state={data.order.state}
        actions={<OrderShowActions order={data.order} />}
      />
      <OrderShowBody order={data.order} />
      <OrdersShowProductDetails order={data.order} />
      <OrderShowRevenue order={data.order} />

      {data?.order?.referral && (
        <ShowReferrals referrals={[data.order.referral]} title="Referral" />
      )}

      <ShowActivity id={data?.order?.id} klass="Order" />
    </>
  );
}

export default OrdersShow;
