import { useMutation } from '@apollo/client';
import {
  EyeIcon,
  FolderArrowDownIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { useApp, useSignedLink } from '@retainerclub/hooks';
import { DESTROY_SCAN_FILE } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function PatientShowScansTableRowActions({
  scanFile,
  refetch,
  handleOpenModal,
}) {
  const [signedUrl, setSignedUrl] = useState(null);
  const { isAdmin } = useApp();

  const { getSignedLink, loading } = useSignedLink();

  const [destroy] = useMutation(DESTROY_SCAN_FILE);

  useEffect(() => {
    if (scanFile.fileUrl && !signedUrl) {
      getSignedLink(scanFile).then((url) => setSignedUrl(url));
    }
  }, [scanFile, getSignedLink, signedUrl]);

  function downloadFile(e) {
    e.preventDefault();

    const link = document.createElement('a');
    link.href = signedUrl;
    link.setAttribute('download', scanFile.originalFileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(signedUrl);
    link.remove();
  }

  async function handleScanDestroy() {
    try {
      const params = {
        variables: {
          input: {
            id: scanFile.id,
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await destroy(params);

      const { scanFile: destroyedFile } = data.destroyScanFile;

      if (destroyedFile && refetch) {
        refetch();
      }
    } catch (err) {
      console.error(err);
    }
  }

  return loading ? null : (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-l-md bg-white p-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 hover:text-rcprimary-400 focus:z-9 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-gray-50"
        onClick={() => handleOpenModal(signedUrl)}
        disabled={!scanFile.fileUrl.endsWith('.stl')}
      >
        <span className="sr-only">View</span>
        <EyeIcon className="size-5" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center rounded-r-md bg-white p-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 hover:text-rcprimary-400 focus:z-9"
        onClick={downloadFile}
      >
        <span className="sr-only">Download</span>
        <FolderArrowDownIcon className="size-5" aria-hidden="true" />
      </button>
      {isAdmin && (
        <button
          type="button"
          className="-ml-px relative inline-flex items-center rounded-r-md bg-white p-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 hover:text-rccallouts-danger focus:z-9"
          onClick={handleScanDestroy}
        >
          <span className="sr-only">Delete</span>
          <TrashIcon className="size-5" aria-hidden="true" />
        </button>
      )}
    </span>
  );
}

PatientShowScansTableRowActions.propTypes = {
  scanFile: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default PatientShowScansTableRowActions;
