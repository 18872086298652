import { TimeRangeSelect } from '@retainerclub/common';
import {
  PerformanceByLocation,
  RetainerRevenue,
  SmileCarePlanCustomers,
  SmileCarePlanRevenue,
  TotalPartnerOfficeRevenue,
  TotalPartnerPlatformRevenue,
  TotalPartnerRevenue,
  WeeklyPerformance,
} from '@retainerclub/dashboards';
import {
  Card,
  Grid,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import { useState } from 'react';

function Dashboard() {
  const [range, setRange] = useState({ id: 'last_thirty', name: '30 Days' });

  return (
    <main>
      <div className="flex flex-row items-center justify-between">
        <div>
          <h3 className="ml-2 font-bold text-2xl text-black/70 leading-6">
            Dashboard
          </h3>
        </div>
        <div className="w-48">
          <TimeRangeSelect value={range} onChange={setRange} />
        </div>
      </div>

      <TabGroup className="mt-4">
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Detail</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid numItemsMd={2} numItemsLg={3} className="mt-2 gap-2">
              <TotalPartnerRevenue variables={{ range: range.id }} />
              <TotalPartnerOfficeRevenue variables={{ range: range.id }} />
              <TotalPartnerPlatformRevenue variables={{ range: range.id }} />
            </Grid>
            <Grid numItemsMd={2} numItemsLg={3} className="mt-2 gap-2">
              <SmileCarePlanRevenue variables={{ range: range.id }} />
              <SmileCarePlanCustomers variables={{ range: range.id }} />
              <RetainerRevenue variables={{ range: range.id }} />
            </Grid>
            <div className="mt-2">
              <WeeklyPerformance />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mt-2">
              <Card>
                <PerformanceByLocation
                  variables={{ range: range.id }}
                  rangeName={range.name}
                />
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  );
}

export default Dashboard;
