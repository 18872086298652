function sourceValueGetter(params) {
  if (params.data.source === 'qr_code') {
    return 'QR Code';
  }

  if (params.data.source === 'partner_link') {
    return 'Manual Referral';
  }

  if (params.data.source === 'admin') {
    return 'Admin';
  }

  if (params.data.source === 'legacy_import') {
    return 'Legacy Import';
  }

  return params?.data?.source?.replace(/_/g, ' ');
}

export default sourceValueGetter;
