import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { GET_PARTNER_USERS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import PartnerUsersListDataTable from './PartnerUsersListDataTable';

function PartnerUsersList({
  hideOrg = false,
  hideLoc = false,
  newTo = '/partners/users/new',
  height = 718,
}) {
  const app = import.meta.env.VITE_APP_NAME;

  const { currentUser } = useAuth();

  const { error, loading, data } = useQuery(GET_PARTNER_USERS);

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Partner Users" />
    );
  }

  return (
    <PartnerUsersListDataTable
      data={data}
      hideLoc={
        app.includes('partner') && currentUser.roleLevel > 0 ? false : hideLoc
      }
      hideOrg={hideOrg}
      newTo={newTo}
      height={height}
    />
  );
}

PartnerUsersList.propTypes = {
  hideOrg: PropTypes.bool,
  hideLoc: PropTypes.bool,
  newTo: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PartnerUsersList;
