import PropTypes from 'prop-types';

function Body(props) {
  const { children } = props;

  return (
    <div className="min-h-full lg:pl-60">
      <main className="relative flex h-full min-h-[calc(100vh-68px)] flex-col gap-y-2 p-2">
        {children}
      </main>
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
