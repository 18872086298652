function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    accountId: record?.accountId || null,
    patientId: record?.patientId || null,
    message: record?.message || '',
  };
}

export default initialValues;
