import { useMutation } from '@apollo/client';
import { ACTION_S3_SIGN_URL } from '@retainerclub/shared-api';

function useSignedLink() {
  const [create, { loading, error }] = useMutation(ACTION_S3_SIGN_URL);

  async function getSignedLink(file) {
    const s3Key = file.fileUrl.split('.com/')[1];

    const { data } = await create({
      variables: {
        input: {
          input: {
            s3Key,
          },
          clientMutationId: crypto.randomUUID(),
        },
      },
    });

    return data.actionSignS3Url.signedS3Link.url;
  }

  return {
    getSignedLink,
    loading,
    error,
  };
}

export default useSignedLink;
