import {
  DateInput,
  EmailInput,
  Fieldset,
  Form,
  Input,
  PhoneInput,
} from '@retainerclub/common';
import {
  CREATE_REFERRAL,
  GET_REFERRAL,
  UPDATE_REFERRAL,
} from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import ReferralsFormPartner from './ReferralsFormPartner';

function ReferralsFormCommon({ initialValues, schema }) {
  const [urlSearchParams] = useSearchParams();

  const fromUrgent = urlSearchParams.get('ref');

  return (
    <Form
      initialValues={initialValues}
      schema={schema}
      sidebarTitle="Referral"
      sidebarDescription="A partner referral for inviting customers into the Retainer Club system."
      createAction={CREATE_REFERRAL}
      updateAction={UPDATE_REFERRAL}
      updateQuery={GET_REFERRAL}
      afterEditLink={`${
        fromUrgent ? 'production-orders/needs-attention' : 'customers/referrals'
      }`}
      afterNewLink="customers/referrals"
      showOnEdit
      showOnNew
      dataName="referral"
    >
      <ReferralsFormPartner />

      <Fieldset
        title="Account Information"
        description="General account holder details"
        doubleCols
      >
        <Input
          className="sm:col-span-6 xl:col-span-3"
          required
          name="userAttributes.firstName"
          label="Account Holder First Name"
        />
        <Input
          className="sm:col-span-6 xl:col-span-3"
          required
          name="userAttributes.lastName"
          label="Account Holder Last Name"
        />
        <EmailInput
          className="sm:col-span-3"
          required
          name="userAttributes.email"
          label="Account Holder Email"
        />
        <PhoneInput
          className="sm:col-span-6 xl:col-span-3"
          required
          name="userAttributes.phone"
          label="Account Holder Phone"
        />
      </Fieldset>

      <Fieldset
        title="Patient Details"
        description="Who is receiving treatment?"
        doubleCols
      >
        <Input
          className="sm:col-span-6 xl:col-span-3"
          required
          name="patientAttributes.firstName"
          label="Patient First Name"
        />
        <Input
          className="sm:col-span-6 xl:col-span-3"
          required
          name="patientAttributes.lastName"
          label="Patient Last Name"
        />
        <DateInput
          dayName="patientAttributes.birthDay"
          monthName="patientAttributes.birthMonth"
          yearName="patientAttributes.birthYear"
          required
          wrapperClass="sm:col-span-6 xl:col-span-3"
        />
      </Fieldset>
    </Form>
  );
}

ReferralsFormCommon.propTypes = {
  initialValues: PropTypes.func.isRequired,
  schema: PropTypes.func.isRequired,
};

export default ReferralsFormCommon;
