import { RcLink } from '@retainerclub/common';
import { Fragment } from 'react';

function orderItemsPatientsCellRenderer(params) {
  let patients = [];

  const orderItems = params?.data?.orderItemsAttributes
    ? params.data.orderItemsAttributes
    : params.data.orderItems;

  if (orderItems === undefined || orderItems === null) {
    return null;
  }

  patients = [
    ...new Set(
      params?.data?.orderItems
        ?.filter((p) => p.patient !== null)
        .map((item) => item.patient),
    ),
  ];

  return patients
    .filter((patient) => patient !== null)
    .map((patient, index) => (
      <Fragment key={patient.id}>
        <RcLink
          to={`/customers/patients/${patient.slug}`}
          className="capitalize"
        >
          {patient.firstName} {patient.lastName}
        </RcLink>
        {patients.length === 0 || patients.length - 1 === index ? '' : ', '}
      </Fragment>
    ));
}

export default orderItemsPatientsCellRenderer;
