import './init';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { ApolloProvider } from '@apollo/client';
import { RootLoader, apolloClient } from '@retainerclub/common';
import '@retainerclub/common/src/config/i18n/i18next';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import awsAuthConfig from './config/amplify/awsAuthConfig';

import '@ag-grid-community/styles/ag-grid.css';
import '@retainerclub/common/src/assets/css/leaflet-backend.css';
import '@retainerclub/common/src/assets/css/tooltips.css';
import '@retainerclub/tables/src/assets/css/ag-theme-rc-light.css';
import './index.css';

Sentry.init({
  dsn: 'https://25a522648d9f33ac3d64867b6e780c84@o1097881.ingest.us.sentry.io/4507137799618560',
  enabled: import.meta.env.VITE_APP_ENV !== 'development',
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/retainerclub.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-045625 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( RetainerClub )_is_granted_a_( Single Application )_Developer_License_for_the_application_( retainerclub )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( retainerclub )_need_to_be_licensed___( retainerclub )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 October 2024 )____[v2]_MTcyNzk5NjQwMDAwMA==3d2781655072a50b3c5313f1c44a0d5f',
);

const ampConfig = awsAuthConfig[import.meta.env.VITE_APP_ENV];

Amplify.configure({ ...ampConfig });

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={<RootLoader />}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
);
