function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    settingGroupOptionIds: Array.isArray(record?.settingGroupOptionIds)
      ? record.settingGroupOptionIds
      : [],
  };
}

export default initialValues;
