import PropTypes from 'prop-types';
import { MainTableLayout } from '../../layouts';
import { patientsValueGetter, usersValueGetter } from '../../shared';

function AccountsListDataTable(props) {
  const { data } = props;

  const columnDefs = [
    {
      field: 'attributes.accountNumber',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      cellStyle: { cursor: 'pointer', fontWeight: 'bold' },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/accounts/${params.data.attributes.slug}`,
        );
      },
    },
    {
      headerName: 'Account Holders',
      flex: 1,
      minWidth: 150,
      filter: 'agTextColumnFilter',
      valueGetter: usersValueGetter,
    },
    {
      headerName: 'Patients',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      valueGetter: patientsValueGetter,
    },
    {
      field: 'attributes.createdAt',
      headerName: 'Date Created',
      type: 'dateColumn',
      sort: 'desc',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'attributes.updatedAt',
      headerName: 'Last Updated',
      type: 'dateColumn',
      suppressHeaderMenuButton: true,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={data.accountsChewy}
      pageTitle="Customer Accounts"
    />
  );
}

AccountsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccountsListDataTable;
