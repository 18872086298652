import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import { Card, LineChart, Title } from '@tremor/react';
import PropTypes from 'prop-types';

function SmileCarePlanCustomersGrouped({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'smile_care_plan_customers_grouped' },
    notifyOnNetworkStatusChange: true,
  });

  const valueFormatter = (number) =>
    `${new Intl.NumberFormat('us').format(number).toString()}`;

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} />;
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <Card>
      <Title>Lifetime Smile Care Plan Customers</Title>
      <LineChart
        className="mt-6"
        data={data.dashboard.metric}
        index="month"
        categories={['Customers']}
        colors={['emerald']}
        valueFormatter={valueFormatter}
        yAxisWidth={40}
      />
    </Card>
  ) : null;
}

SmileCarePlanCustomersGrouped.propTypes = {
  variables: PropTypes.object,
};

export default SmileCarePlanCustomersGrouped;
