import {
  AdjustmentsHorizontalIcon,
  QrCodeIcon,
} from '@heroicons/react/20/solid';
import { SelectMenuItem } from '@retainerclub/common';
import PropTypes from 'prop-types';

function PartnerLocsShowActions({ setOpen, slug }) {
  const app = import.meta.env.VITE_APP_NAME;

  let url = '/partners/locations/';

  if (app.includes('partner')) {
    url = '/locations/';
  }

  return (
    <>
      <SelectMenuItem onClick={() => setOpen(true)} icon={QrCodeIcon}>
        Generate QR Code
      </SelectMenuItem>
      <SelectMenuItem
        to={`${url}${slug}/case-settings`}
        icon={AdjustmentsHorizontalIcon}
      >
        Case Setting Defaults
      </SelectMenuItem>
    </>
  );
}

PartnerLocsShowActions.propTypes = {
  setOpen: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
};

export default PartnerLocsShowActions;
