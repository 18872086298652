import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
    email: Yup.string().email().required('email is required'),
    phone: Yup.string().required('phone number is required'),
  });
}

export default schema;
