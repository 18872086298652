import { useMutation, useQuery } from '@apollo/client';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import {
  Button,
  DeleteButton,
  LoadingOrError,
  timestampFormatter,
} from '@retainerclub/common';
import {
  DESTROY_ACCOUNT_NOTE,
  GET_ACCOUNT_NOTES,
} from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import AccountNoteModal from '../../Accounts/AccountNotes/AccountNoteModal';

function PatientShowAccountNotes({ patientId, accountId }) {
  const [open, setOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_ACCOUNT_NOTES, {
    variables: { patientId },
  });

  const [destroy] = useMutation(DESTROY_ACCOUNT_NOTE);

  const destroyNote = useCallback(
    async (id) => {
      try {
        await destroy({
          variables: {
            input: {
              id,
              clientMutationId: crypto.randomUUID(),
            },
          },
        });

        refetch();
      } catch (err) {
        console.error('destroyNote error', err);
      }
    },
    [destroy, refetch],
  );

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Account Notes" />;
  }

  return (
    <div className="flex min-h-64 w-full flex-row gap-2">
      <div className="basis-full pt-2 pr-2 md:basis-2/3">
        {data?.accountNotes && data.accountNotes.length > 0 ? (
          <div className="flow-root">
            <ul className="-mb-8">
              {data.accountNotes.map((accountNote, accountNoteIdx) => (
                <li key={accountNote.id} className="relative">
                  <div className="relative pb-8">
                    {accountNoteIdx !== data.accountNotes.length - 1 ? (
                      <span
                        aria-hidden="true"
                        className="-ml-px absolute top-5 left-5 h-full w-0.5 bg-gray-200"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      <div className="relative">
                        <img
                          alt=""
                          src="https://cdn.retainerclub.com/images/defaults/default-user.jpeg"
                          className="flex size-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                        />

                        <span className="-bottom-0.5 -right-1 absolute rounded-tl bg-white px-0.5 py-px">
                          <ChatBubbleLeftEllipsisIcon
                            aria-hidden="true"
                            className="size-5 text-gray-400"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm">
                            <span className="font-medium text-gray-900">
                              {accountNote.accountNoteable.firstName}&nbsp;
                              {accountNote.accountNoteable.lastName}&nbsp;
                              <span className="text-xs">
                                (
                                {accountNote.accountNoteableType === 'AdminUser'
                                  ? 'Admin'
                                  : 'Partner'}
                                )
                              </span>
                            </span>
                          </div>
                          <p className="mt-0.5 text-gray-500 text-xs">
                            {timestampFormatter(accountNote.createdAt)}
                          </p>
                        </div>
                        <div className="mt-1 text-gray-700 text-sm">
                          <p>{accountNote.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0">
                    <DeleteButton
                      onClick={() => destroyNote(accountNote.id)}
                      size="sm"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex size-full flex-col items-center justify-center">
            <h3 className="font-medium text-xl">No account notes</h3>
          </div>
        )}
      </div>
      <div className="flex h-full basis-full justify-end md:basis-1/3">
        <div className="align-right">
          <Button add onClick={() => setOpen(true)} name="Add Account Note" />
        </div>
      </div>
      <AccountNoteModal
        open={open}
        handleCloseModal={() => setOpen(false)}
        patientId={patientId}
        accountId={accountId}
        refetch={refetch}
      />
    </div>
  );
}

PatientShowAccountNotes.propTypes = {
  patientId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default PatientShowAccountNotes;
