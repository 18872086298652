function imageCellRender(params) {
  const src =
    params.value || 'https://cdn.retainerclub.com/images/defaults/default.svg';

  return (
    <img
      src={src}
      alt="logo"
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        height: 'auto',
        margin: '0 auto',
      }}
    />
  );
}

export default imageCellRender;
