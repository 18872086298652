import PropTypes from 'prop-types';
import { MainTableLayout } from '../../layouts';
import {
  dobValueGetter,
  emailCellRenderer,
  eotValueGetter,
  importedCellRenderer,
  importedValueGetter,
  includeEotCellRenderer,
  madePurchaseValueGetter,
  nameValueGetter,
} from '../../shared';

function PatientsListDataTable({ data, height = 718, pageTitle = 'Patients' }) {
  const columnDefs = [
    {
      field: 'name',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      valueGetter: nameValueGetter,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: 'bold',
      },
      onCellClicked: (params) => {
        params.context.navigate(`/customers/patients/${params.data.slug}`);
      },
    },
    {
      field: 'DOB',
      minWidth: 120,
      valueGetter: dobValueGetter,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Account Holder',
      minWidth: 150,
      suppressHeaderMenuButton: true,
      flex: 1,
      filter: 'agTextColumnFilter',
      valueGetter: (params) =>
        `${params?.data?.account?.users[0]?.firstName} ${params?.data?.account?.users[0]?.lastName}`,
      cellStyle: {
        cursor: 'pointer',
        textTransform: 'capitalize',
      },
      onCellClicked: (params) => {
        params.context.navigate(
          `/customers/users/${params?.data?.account?.users[0]?.slug}`,
        );
      },
    },
    {
      headerName: 'Account Email',
      minWidth: 250,
      valueGetter: (params) => `${params?.data?.account?.users[0]?.email}`,
      cellRenderer: emailCellRenderer,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'createdAt',
      sort: 'desc',
      headerName: 'Date Created',
      type: 'dateColumn',
      suppressHeaderMenuButton: true,
    },
    {
      field: 'imported',
      headerName: '1.0 Import?',
      suppressHeaderMenuButton: true,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: importedValueGetter,
      cellRenderer: importedCellRenderer,
    },
    {
      field: 'includeEot',
      headerName: 'EOT Purchased?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: eotValueGetter,
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'orders',
      headerName: 'Made Purchase?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: madePurchaseValueGetter,
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'settingsOverride',
      headerName: 'Custom Lab Notes?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: (params) => (params?.data?.settingsOverride ? 'yes' : 'no'),
      cellRenderer: includeEotCellRenderer,
    },
    {
      field: 'scanReady',
      headerName: 'Scan Available?',
      suppressHeaderMenuButton: true,
      minWidth: 120,
      filter: 'agSetColumnFilter',
      wrapHeaderText: true,
      valueGetter: (params) => (params?.data?.scanReady ? 'yes' : 'no'),
      cellRenderer: includeEotCellRenderer,
    },
  ];

  return (
    <MainTableLayout
      columnDefs={columnDefs}
      rowData={data.patients}
      pageTitle={pageTitle}
      height={height}
    />
  );
}

PatientsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
  pageTitle: PropTypes.string,
};

export default PatientsListDataTable;
