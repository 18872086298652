import { useQuery } from '@apollo/client';
import { TabGroup, TabList, TabPanel } from '@headlessui/react';
import {
  Card,
  LoadingOrError,
  Modal,
  RcTab,
  RcTabPanels,
  ShowBody,
  ShowDetails,
  ShowHeader,
  ShowTable,
} from '@retainerclub/common';
import { useApp } from '@retainerclub/hooks';
import {
  GET_PARTNER_LOC,
  GET_PARTNER_USERS,
  GET_REFERRALS,
  GET_USERS,
} from '@retainerclub/shared-api';
import {
  OrdersList,
  PartnerUsersListDataTable,
  ReferralsListDataTable,
  ReferralsProductionList,
  UsersListDataTable,
} from '@retainerclub/tables';
import { useMemo, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate, useParams } from 'react-router-dom';
import PartnerLocsShowActions from './PartnerLocsShowActions';

function PartnerLocsShow() {
  const [open, setOpen] = useState(false);
  const { slug } = useParams();
  const navigation = useNavigate();

  const { isAdmin, isPartner } = useApp();

  const url = useMemo(
    () => (isPartner ? '/locations' : '/partners/locations'),
    [isPartner],
  );

  const { data, loading, error } = useQuery(GET_PARTNER_LOC, {
    variables: { slug },
  });

  if (slug === null || slug === undefined || slug === 'undefined') {
    navigation(url);
  }

  if (loading || error) {
    return <LoadingOrError error={error} loading title="Partner Location" />;
  }

  return (
    <>
      <ShowHeader
        title={`${isAdmin ? 'Partner Location' : 'Location'}`}
        name={data.partnerLocation.name}
        active={data.partnerLocation.active}
        editTo={`${url}/${data.partnerLocation.slug}/edit`}
        listTo={url}
        imageUrl={data.partnerLocation.imageUrl}
        imageAlt={data.partnerLocation.name}
        actions={<PartnerLocsShowActions setOpen={setOpen} slug={slug} />}
      />
      <ShowBody
        address={data.partnerLocation.address}
        shippingAddress={data.partnerLocation.partnerShippingAddress}
        email={data.partnerLocation.email}
        phone={data.partnerLocation.phone}
        website={data.partnerLocation.website}
      >
        <Card
          className="h-full p-4"
          heading="Details"
          wrapperClassName="h-full"
        >
          <ShowDetails record={data.partnerLocation} />
        </Card>
      </ShowBody>

      <TabGroup as="div">
        <div className="-mx-4 flex overflow-x-auto sm:mx-0">
          <div className="ml-4 flex-auto sm:px-0">
            <TabList className="flex space-x-2">
              <RcTab name="Partner Users" />
              <RcTab name="Customers" />
              <RcTab name="Referrals" />
              <RcTab name="Needs Attention" />
              <RcTab name="Awaiting Scan" />
              <RcTab name="All Purchases" />
              <RcTab name="Future Fulfillment" />
              <RcTab name="Never Purchased" />
            </TabList>
          </div>
        </div>
        <RcTabPanels>
          <TabPanel>
            {data?.partnerLocation && (
              <ShowTable
                partnerLocationId={data.partnerLocation.id}
                partnerOrganizationId={
                  data.partnerLocation.partnerOrganizationId
                }
                query={GET_PARTNER_USERS}
                dataTable={PartnerUsersListDataTable}
                hideOrg
                hideLoc
                loadingTitle="Partner Users"
                newTo={`${isAdmin ? '/partners' : ''}/users/new`}
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <ShowTable
                partnerLocationId={data.partnerLocation.id}
                partnerOrganizationId={
                  data.partnerLocation.partnerOrganizationId
                }
                query={GET_USERS}
                dataTable={UsersListDataTable}
                loadingTitle="Customers"
                newTo="/customers/users/new"
                hideLoc
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <ShowTable
                partnerLocationId={data.partnerLocation.id}
                partnerOrganizationId={
                  data.partnerLocation.partnerOrganizationId
                }
                query={GET_REFERRALS}
                hideLoc
                dataTable={ReferralsListDataTable}
                loadingTitle="Referrals"
                newTo="/customers/referrals/new"
              />
            )}
          </TabPanel>

          <TabPanel>
            {data?.partnerLocation && (
              <ReferralsProductionList
                pageTitle="Needs Attention"
                hideActions
                variables={{
                  partnerLocationId: data.partnerLocation.id,
                  scanReady: false,
                  activeAccount: true,
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <ReferralsProductionList
                pageTitle="Awaiting Scan"
                hideActions
                variables={{
                  partnerLocationId: data.partnerLocation.id,
                  withoutScan: true,
                  activeAccount: true,
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <OrdersList
                hideActions
                variables={{
                  partnerLocationId: data.partnerLocation.id,
                  futureFulfillment: false,
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <OrdersList
                hideActions
                variables={{
                  partnerLocationId: data.partnerLocation.id,
                  futureFulfillment: true,
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            {data?.partnerLocation && (
              <ReferralsProductionList
                pageTitle="Never Purchased"
                hideActions
                variables={{
                  partnerLocationId: data.partnerLocation.id,
                  withoutOrders: true,
                  withoutScan: false,
                  activeAccount: true,
                }}
              />
            )}
          </TabPanel>
        </RcTabPanels>
      </TabGroup>
      <Modal open={open} setOpen={setOpen}>
        <div className="mx-auto flex flex-col items-center justify-center">
          <QRCode
            value={`${
              import.meta.env.VITE_CUSTOMER_APP
            }/onboarding/get-started?pId=${data.partnerLocation.id}`}
            size={400}
            fgColor="#000"
            logoImage="https://cdn.retainerclub.com/images/brand/RC_Smile.png"
            removeQrCodeBehindLogo
            logoPadding={10}
          />

          <div className="mt-4 text-center text-black text-xs">
            <div className="my-5 font-bold text-xl">
              {data.partnerLocation.name}
            </div>
            {`${import.meta.env.VITE_CUSTOMER_APP}/onboarding/get-started?pId=${
              data.partnerLocation.id
            }`}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PartnerLocsShow;
