import { getDefaultBooleanValue } from '../../../../../transforms';

function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    firstName: record?.firstName || null,
    lastName: record?.lastName || null,
    birthDay: record?.birthDay || 'Day',
    birthMonth: record?.birthMonth || 'Month',
    birthYear: record?.birthYear || null,
    productIds: [],
    settingsOverride: getDefaultBooleanValue(record?.settingsOverride, false),
    settingGroupOptionIds:
      Array.isArray(record?.settingGroupOptionIds) &&
      record?.settingGroupOptionIds?.length > 0
        ? record.settingGroupOptionIds
        : [],
    notes: record?.notes || null,
    includeEot: getDefaultBooleanValue(record?.includeEot, false),
    scanReadyAt: record?.scanReadyAt || null,
    scanSentAt: record?.scanSentAt || null,
    scanReady: getDefaultBooleanValue(record?.scanReady, false),
    sendToClinic: getDefaultBooleanValue(record?.sendToClinic, false),
  };
}

export default initialValues;
