import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

function DashboardTileLink({
  icon: Icon,
  title,
  description = null,
  metric,
  deltaType,
  deltaPercent = null,
  delta = null,
  to = null,
  isCurrency = false,
  isPercentage = false,
}) {
  const USDollar = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    [],
  );

  const metricString = useMemo(() => {
    if (isCurrency) {
      return USDollar.format(Math.round(metric / 100));
    }

    return metric;
  }, [USDollar, isCurrency, metric]);

  return (
    <div
      className={`relative overflow-hidden rounded-lg bg-white/80 px-4 ${to ? 'pb-12' : ''} pt-5 shadow sm:px-6 sm:pt-6`}
    >
      <dt>
        <div className="absolute rounded-md bg-rcprimary-100 p-3">
          <Icon className="size-6 text-gray-900" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate font-medium text-black/60 text-sm">
          {title}
        </p>
        {description && (
          <p className="ml-16 truncate font-medium text-black/50 text-xs italic">
            {description}
          </p>
        )}
      </dt>
      <dd className="ml-16 flex items-baseline pb-4 sm:pb-4">
        <p className="font-semibold text-2xl text-black">
          {metricString}
          {isPercentage && '%'}
        </p>
        <p
          className={`${
            deltaType === 'increase' ? 'text-green-600' : 'text-red-600'
          }ml-2 flex items-baseline font-semibold text-sm`}
        >
          {deltaType === 'increase' ? (
            <ArrowUpIcon
              className="size-5 shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ArrowDownIcon
              className="size-5 shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
          )}
          {isCurrency ? USDollar.format(Math.round(delta / 100)) : delta}
          {delta !== null && deltaPercent !== null ? ' / ' : ''}
          {deltaPercent !== null && `${Number(deltaPercent).toFixed(1)}%`}
        </p>
        {to && (
          <div className="absolute inset-x-0 bottom-0 bg-rcgray-400/20 p-4 sm:px-6">
            <div className="text-sm">
              <NavLink
                to={to}
                className="font-medium text-rcprimary-400 hover:text-rcprimary-600"
              >
                View all
              </NavLink>
            </div>
          </div>
        )}
      </dd>
    </div>
  );
}

DashboardTileLink.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  metric: PropTypes.number.isRequired,
  deltaType: PropTypes.string.isRequired,
  delta: PropTypes.number,
  deltaPercent: PropTypes.number,
  to: PropTypes.string,
  isCurrency: PropTypes.bool,
};

export default DashboardTileLink;
