function initialValues(props) {
  const { record = {} } = props;

  return {
    id: record?.id || null,
    firstName: record?.firstName || '',
    lastName: record?.lastName || '',
    email: record?.email || '',
    phone: record?.phone || '',
  };
}

export default initialValues;
