import { useQuery } from '@apollo/client';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { LoadingOrError } from '@retainerclub/common';
import { GET_DASHBOARD } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import DashboardTileLink from './DashboardTileLink';

function TotalReferrals({ variables = {} }) {
  const { error, loading, data } = useQuery(GET_DASHBOARD, {
    variables: { ...variables, metric: 'total_referrals' },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error) {
    return (
      <div className="relative h-[152px] overflow-hidden rounded-lg bg-white/10 px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <LoadingOrError error={error} loading={loading} />
      </div>
    );
  }

  return data?.dashboard?.metric !== null &&
    data?.dashboard?.metric !== undefined ? (
    <DashboardTileLink
      title="Total Referrals Sent"
      metric={data.dashboard.metric}
      delta={data.dashboard.delta}
      deltaPercent={data.dashboard.delta_percent}
      deltaType={data.dashboard.delta_type}
      to="/customers/referrals"
      icon={PaperAirplaneIcon}
    />
  ) : null;
}

TotalReferrals.propTypes = {
  variables: PropTypes.object,
};

export default TotalReferrals;
